import type { StoryContext, StoryFn } from "@storybook/react";
import ThemeWrapper from "../helpers/ThemeWrapper";
import {
  DecoratorHelpers,
  type ThemesParameters,
} from "@storybook/addon-themes";
import { useColorScheme } from "@mui/joy/styles";
import { useColorScheme as useMUIColorScheme } from "@mui/material/styles";
import React from "react";

const { initializeThemeState, pluckThemeFromContext } = DecoratorHelpers;

function ModeToggle({ newMode }: { newMode: "light" | "dark" }) {
  const { setMode } = useColorScheme();
  const { setMode: setMUIMode } = useMUIColorScheme();

  React.useLayoutEffect(() => {
    setMode(newMode);
    setMUIMode(newMode);
  }, [newMode, setMode, setMUIMode]);

  return null;
}

declare module "@storybook/react" {
  interface Parameters {
    themes?: { themeOverride: ThemesParameters };
    [name: string]: unknown;
  }
}

export default function ThemeDecorator() {
  initializeThemeState(["light", "dark"], "light");

  function Decorator(Story: StoryFn, context: StoryContext) {
    const { themeOverride } = context.parameters.themes ?? {};
    const selected = pluckThemeFromContext(context);

    const theme = React.useMemo(() => {
      return (themeOverride || selected || "light") as "light" | "dark";
    }, [selected, themeOverride]);

    return (
      <ThemeWrapper defaultMode={theme}>
        <ModeToggle newMode={theme} />
        <Story context={context} />
      </ThemeWrapper>
    );
  }
  return Decorator;
}
