import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "task-planned";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M12 3.25C7.16751 3.25 3.25 7.16751 3.25 12C3.25 16.8325 7.16751 20.75 12 20.75C16.8325 20.75 20.75 16.8325 20.75 12C20.75 7.16751 16.8325 3.25 12 3.25ZM4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12Z";
const prefix = "fac";

export const TaskPlanned: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(TaskPlanned);
