import { Box, Button, CircularProgress } from "@mui/joy";
import { useIntersectionObserver } from "library-frontend-utils/hooks";

type InfinityScrollButtonProps = {
  hasNextPage: boolean;
  isLoading: boolean;
  isError?: boolean;
  onTriggerFetchNextPage?: () => unknown;
  loadMoreText: string;
};

export default function InfinityScrollButton({
  hasNextPage,
  isLoading,
  isError = false,
  loadMoreText,
  onTriggerFetchNextPage,
}: InfinityScrollButtonProps) {
  const { registerRef } = useIntersectionObserver(onTriggerFetchNextPage);

  return (
    hasNextPage &&
    !isError && (
      <Box display="flex" justifyContent="center" ref={registerRef}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="plain"
            color="neutral"
            onClick={() => {
              if (onTriggerFetchNextPage) void onTriggerFetchNextPage();
            }}
          >
            {loadMoreText}
          </Button>
        )}
      </Box>
    )
  );
}
