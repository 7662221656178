import type { EChartsOption } from "echarts";
import colors from "library-design-system/tokens/colors";
import { formatNumber } from "library-frontend-utils/helpers";
import TooltipFormatter from "./TooltipFormatter";
import {
  calculateMaxXValue,
  generateMarkAreasData,
  getMarkAreaLabelColor,
} from "./utils";
import type useTooltipContainer from "../../helpers/useTooltipContainer";

export function createChartOptions({
  dsvCost,
  afeCost,
  dsvPredictiveCost,
  afeActivities,
  currency,
  t,
  tooltipContainer,
  chartWidth,
  isDark,
}: {
  dsvCost: [duration: number, cost: number][];
  afeCost: [duration: number, cost: number][];
  dsvPredictiveCost: [duration: number, cost: number][];
  afeActivities: { name?: string; xAxis: number }[][];
  currency?: string;
  t: Record<string, string>;
  tooltipContainer: ReturnType<typeof useTooltipContainer>;
  chartWidth: number;
  isDark: boolean;
}): EChartsOption {
  const baseTextStyle = {
    fontFamily: "Inter",
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 16,
    color: isDark ? colors["grey"][400] : colors["grey"][600],
  };
  const maxXValue = calculateMaxXValue(dsvCost, afeCost);

  const markAreasData = generateMarkAreasData(
    afeActivities,
    maxXValue,
    dsvCost,
    isDark,
    chartWidth
  );

  const getMarkAreaLabel = getMarkAreaLabelColor(dsvCost, isDark);

  const seriesMetadata = {
    dsvCost: {
      title: t["Est. VoWD"],
      color: isDark ? colors["grey"][100] : colors["grey"][900],
    },
    afeCost: {
      title: t["Planned AFE"],
      color: isDark ? colors["grey"][600] : colors["grey"][400],
    },
    dsvCostPrediction: {
      title: t["DSV Cost Prediction"],
      color: isDark ? colors["grey"][100] : colors["grey"][900],
    },
    activityDuration: {
      title: t["Actual activity duration"],
      color: colors.blue[400],
    },
  };

  return {
    legend: [
      {
        show: true,
        selectedMode: false,
        itemStyle: { color: "transparent" },
        data:
          dsvCost.length > 0
            ? [
                seriesMetadata.dsvCost.title,
                seriesMetadata.dsvCostPrediction.title,
                seriesMetadata.afeCost.title,
              ]
            : [seriesMetadata.afeCost.title],
        right: 165,
        top: 0,
        itemWidth: 20,
        itemHeight: 25,
        itemGap: 15,
      },
      {
        show: true,
        selectedMode: true,
        itemStyle: {
          color: colors["blue"][100],
          borderWidth: 1,
          borderColor: colors["blue"][400],
        },
        data: [seriesMetadata.activityDuration.title],
        itemWidth: 16,
        itemHeight: 16,
        itemGap: 15,
        right: 0,
      },
    ],
    tooltip: {
      trigger: "axis",
      show: true,
      borderWidth: 0,
      backgroundColor: "transparent",
      extraCssText: "box-shadow: none;",
      axisPointer: {
        type: "line",
        snap: false,
        z: 1,
        lineStyle: {
          color: isDark ? colors["grey"][600] : colors["grey"][300],
          type: "dashed",
        },
      },
      formatter: (params) => {
        return tooltipContainer(TooltipFormatter, params, {
          currency,
          dsvCost,
          afeCost,
        });
      },
    },
    xAxis: {
      name: t["Days"],
      splitLine: { show: false },
      nameLocation: "middle",
      nameGap: 30,
      min: 0,
      type: "value",
      axisLabel: {
        formatter: (value: number) =>
          Number.isInteger(value)
            ? String(value)
            : formatNumber(Number(value), 1),
      },
      nameTextStyle: { ...baseTextStyle },
    },
    yAxis: {
      name: currency ? `Cost (${currency})` : t["Cost (NOK)"],
      nameLocation: "end",
      nameTextStyle: { padding: [0, 20, 5, 0] },
      startValue: 0,
      min: 0,
      type: "value",
      axisLine: { onZero: false, show: false },
      splitLine: {
        lineStyle: {
          type: "solid",
          color: isDark ? colors["grey"][600] : colors["grey"][300],
        },
      },
      axisLabel: {
        color: isDark ? colors["grey"][400] : colors["grey"][600],
        formatter: function (value) {
          if (value >= 1000000) {
            return Math.round(value / 1000000).toString() + "M";
          }

          return formatNumber(value, 0);
        },
      },
      axisTick: {
        lineStyle: {
          color: isDark ? colors["grey"][600] : colors["grey"][300],
        },
      },
    },
    grid: { left: 20, right: 20, containLabel: true },
    series: [
      // DSV Cost line
      {
        data: dsvCost,
        animation: false,
        name: seriesMetadata.dsvCost.title,
        type: "line" as const,
        itemStyle: { color: seriesMetadata.dsvCost.color },
        lineStyle: {
          type: "solid",
          color: seriesMetadata.dsvCost.color,
        },
        z: 10,
        zlevel: 10,
        showSymbol: false,
        symbol: "circle",
        symbolSize: 4,
        emphasis: {
          itemStyle: {
            color: isDark ? colors["grey"][100] : colors["grey"][900],
            borderColor: isDark ? colors["grey"][900] : colors["grey"][100],
            borderWidth: 2,
          },
        },
      },
      // AFE Cost line
      {
        data: afeCost,
        name: seriesMetadata.afeCost.title,
        type: "line" as const,
        itemStyle: { color: seriesMetadata.afeCost.color },
        lineStyle: { color: seriesMetadata.afeCost.color },
        symbolSize: dsvCost.length > 0 ? 0 : 4,
        silent: dsvCost.length > 0 ? true : false,
        animation: false,
        zlevel: 10,
        emphasis: {
          scale: dsvCost.length > 0 ? 0 : 2,
          disabled: dsvCost.length > 0 ? true : false,
          itemStyle: {
            color: colors["grey"][100],
            borderColor: colors["grey"][900],
            borderWidth: 2,
          },
          lineStyle: { color: seriesMetadata.afeCost.color },
        },
      },
      // DSV Cost Prediction line
      {
        data: dsvPredictiveCost,
        name: seriesMetadata.dsvCostPrediction.title,
        type: "line" as const,
        animation: false,
        zlevel: 10,
        itemStyle: {
          color: seriesMetadata.dsvCostPrediction.color,
        },
        lineStyle: {
          type: "dashed",
          color: seriesMetadata.dsvCostPrediction.color,
        },
        showSymbol: false,
        symbol: "circle",
        symbolSize: 4,
        emphasis: {
          scale: 2,
          lineStyle: { width: 2 },
          itemStyle: {
            color: colors["grey"][100],
            borderColor: colors["grey"][900],
            borderWidth: 2,
          },
        },
      },
      // Activities markArea series (separate from AFE Cost line)
      {
        name: t["Actual activity duration"],
        type: "custom",
        renderItem: () => {
          return { type: "rect", children: [] };
        },
        data: [0],
        silent: true,
        markArea: {
          silent: true,
          label: {
            color: getMarkAreaLabel,
            fontSize: 10,
            lineHeight: 1.5,
            fontWeight: 300,
            rotate: -90,
            position: ["50%", 10],
            fontFamily: "Inter",
            show: true,
            formatter: function (params) {
              const text = params.name || "Activity";
              const MAX_CHARS = 50;
              if (text.length > MAX_CHARS) {
                return text.substring(0, MAX_CHARS - 3) + "...";
              }

              return text;
            },
          },
          data: markAreasData,
        },
      },
    ],
  };
}
