import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "question-rounded";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M18.5 12C18.5 9.6875 17.25 7.5625 15.25 6.375C13.2188 5.21875 10.75 5.21875 8.75 6.375C6.71875 7.5625 5.5 9.6875 5.5 12C5.5 14.3438 6.71875 16.4688 8.75 17.6562C10.75 18.8125 13.2188 18.8125 15.25 17.6562C17.25 16.4688 18.5 14.3438 18.5 12ZM4 12C4 9.15625 5.5 6.53125 8 5.09375C10.4688 3.65625 13.5 3.65625 16 5.09375C18.4688 6.53125 20 9.15625 20 12C20 14.875 18.4688 17.5 16 18.9375C13.5 20.375 10.4688 20.375 8 18.9375C5.5 17.5 4 14.875 4 12ZM9.28125 9.1875C9.53125 8.46875 10.1875 8 10.9375 8H12.75C13.8438 8 14.75 8.90625 14.75 10C14.75 10.6875 14.3438 11.3438 13.75 11.6875L12.75 12.2812C12.7188 12.6875 12.4062 13 12 13C11.5625 13 11.25 12.6875 11.25 12.25V11.8438C11.25 11.5625 11.375 11.3125 11.625 11.1875L13 10.4062C13.1562 10.3125 13.25 10.1562 13.25 10C13.25 9.71875 13.0312 9.53125 12.75 9.53125H10.9375C10.8438 9.53125 10.75 9.59375 10.7188 9.6875L10.6875 9.71875C10.5625 10.125 10.125 10.3125 9.75 10.1875C9.34375 10.0312 9.15625 9.59375 9.28125 9.21875V9.1875ZM11 15C11 14.4688 11.4375 14 12 14C12.5312 14 13 14.4688 13 15C13 15.5625 12.5312 16 12 16C11.4375 16 11 15.5625 11 15Z";
const prefix = "fac";

export const Question: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(Question);
