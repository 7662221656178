import type { BarSeriesOption, CustomSeriesOption } from "echarts";
import { renderItem } from "./renderItem";
import type { Theme } from "@mui/joy";
import { OPERATION_TYPES } from "../constants";
import type useColors from "./useColors";
import type { AssetActivityChartProps } from "../types";

export type OperationTypes =
  | "Completion"
  | "Drilling"
  | "FormationEvaluation"
  | "Interruption"
  | "Moving"
  | "PlugAbandon"
  | "NotAvailable"
  | "Workover";

export const makeSeries = (
  wellbores: NonNullable<
    AssetActivityChartProps["activityData"]
  >[number]["wellbores"],
  operations: NonNullable<
    AssetActivityChartProps["activityData"]
  >[number]["operations"],
  colors: NonNullable<ReturnType<typeof useColors>>,
  operationTypeLegendNames: Record<OperationTypes, string>,
  theme: Theme,
  displayFullLegend: boolean,
  startDate: Date,
  endDate: Date
): CustomSeriesOption[] | BarSeriesOption[] => {
  // If there isn't any data, return static legend names
  if (displayFullLegend) {
    return OPERATION_TYPES.map((op) => ({
      type: "bar",
      name: operationTypeLegendNames[op],
      itemStyle: {
        color: colors.operations[op],
      },
    }));
  }

  return operations.map((op) => {
    const hoverColor = colors.operationsHover[op.type];

    return {
      type: "custom" as const,
      name: operationTypeLegendNames[op.type], // this controls the legend
      renderItem: (params, api) => renderItem(params, api, theme),
      encode: { x: [1, 2], y: 0 },
      legendHoverLink: false,
      animation: false,
      data: wellbores.flatMap((w, index) => {
        return op.activities
          .filter(
            (activity) =>
              activity.wellboreId === w.facilityWellboreId || w.isTitle
          )
          .map((activity) => {
            let hasLeftNeighbor = false;
            let hasRightNeighbor = false;
            const facilityName = w.isTitle ? w.name : "";
            const operationTypeName = operationTypeLegendNames[op.type];

            operations.forEach((otherOp) => {
              otherOp.activities.forEach((otherAct) => {
                if (otherAct.wellboreId === w.facilityWellboreId) {
                  const activityStartDate = new Date(
                    activity.startDate
                  ).getTime();
                  const activityEndDate = new Date(activity.endDate).getTime();
                  const otherActStartDate = new Date(
                    otherAct.startDate
                  ).getTime();
                  const otherActEndDate = new Date(otherAct.endDate).getTime();

                  const pickerStartDate = new Date(startDate).getTime();
                  const pickerEndDate = new Date(endDate).getTime();

                  if (pickerStartDate > activityStartDate) {
                    hasLeftNeighbor = true;
                  }
                  if (pickerEndDate < activityEndDate) {
                    hasRightNeighbor = true;
                  }

                  const isActivityInRange =
                    activityStartDate > otherActStartDate &&
                    activityEndDate < otherActEndDate;

                  if (isActivityInRange) {
                    hasLeftNeighbor = true;
                    hasRightNeighbor = true;
                  }

                  if (activityStartDate === otherActEndDate) {
                    hasLeftNeighbor = true;
                  }
                  if (activityEndDate === otherActStartDate) {
                    hasRightNeighbor = true;
                  }
                }
              });
            });

            return [
              index,
              activity.startDate,
              activity.endDate,
              op.type,
              w.name,
              colors.operations[op.type],
              w.facilityWellboreId,
              hasLeftNeighbor,
              hasRightNeighbor,
              facilityName,
              hoverColor,
              w.id,
              false,
              operationTypeName,
            ];
          });
      }),
      z: 10,
    };
  });
};
