import { useRouteError } from "react-router-dom";
import { ErrorPageMessage } from "library-ui-components";
import HttpResponseError from "./HttpResponseError";
import { Box } from "@mui/joy";
import type { ComponentProps } from "react";
import AssetLoadingError from "./AssetLoadingError";

function cleanSession() {
  document.cookie.split(";").forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  });
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location.reload();
}

function ErrorPage({
  errorTitle,
  errorMessage,
}: Partial<ComponentProps<typeof ErrorPageMessage>>) {
  const error = useRouteError();
  const newProps = (): ComponentProps<typeof ErrorPageMessage> => {
    if (error instanceof HttpResponseError && error.status === 404)
      return {
        errorTitle: "Page not found",
        errorMessage:
          "It seems this page went out for a coffee and forgot to come back ☕️",
      };
    if (error instanceof AssetLoadingError && error.status === 404)
      return {
        errorTitle: "New version available!",
        errorMessage:
          "We have a new version of the app available. Please reload the page.",
      };
    return {
      errorTitle: errorTitle ?? "Something went wrong",
      errorMessage: errorMessage ?? [
        "Looks like we had an unexpected error and we couldn't recover from it.",
        "But don't worry, we are already alerted of this error. Click on the button below to reload the page.",
      ],
    };
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <ErrorPageMessage
        {...newProps()}
        buttonLabel="Reload the page"
        onClick={cleanSession}
      />
    </Box>
  );
}

export default ErrorPage;
