import { type Theme } from "@mui/joy";
import colors from "../../tokens/colors";

function echartsTheme(theme: Theme) {
  const baseTextStyle = {
    fontFamily: "Inter",
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 16,
    color: theme.palette.neutral[600],
  };

  return {
    color: [
      theme.palette.warning[400],
      colors.purple[400],
      colors.lightGreen[500],
      theme.palette.primary[400],
      colors.pink[400],
      colors.orange[400],
      colors.lime[400],
      colors.moss[400],
    ],
    backgroundColor: theme.palette.common.white,
    rowBackground: theme.palette.neutral[800],
    textStyle: {},
    title: {
      textStyle: {
        color: theme.palette.neutral[800],
        fontWeight: 300,
        fontSize: 20,
        fontFamily: "Inter",
        lineHeight: 24,
      },
      subtextStyle: { color: theme.palette.neutral[800] },
    },
    line: {
      itemStyle: { borderWidth: 1 },
      lineStyle: { width: 2, color: colors.alphaBlack[200] },
      symbolSize: 4,
      symbol: "circle",
      showSymbol: false,
      smooth: false,
      emphasis: {
        scale: 2.5,
        lineStyle: { width: 2 },
      },
    },
    radar: {
      itemStyle: { borderWidth: 1 },
      lineStyle: { width: 2 },
      symbolSize: 4,
      symbol: "circle",
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: "0",
        barBorderColor: theme.palette.neutral[400],
      },
    },
    pie: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    scatter: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    boxplot: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    parallel: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    sankey: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    funnel: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    gauge: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    candlestick: {
      itemStyle: {
        color: colors.aubergine[500],
        color0: colors.lime[300],
        borderColor: colors.aubergine[500],
        borderColor0: colors.lime[300],
        borderWidth: "1",
      },
    },
    graph: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
      lineStyle: { width: "1", color: theme.palette.neutral[800] },
      symbolSize: 4,
      symbol: "circle",
      smooth: false,
      color: [
        theme.palette.primary[500],
        theme.palette.success[500],
        colors.purple[400],
        colors.lime[300],
        colors.pink[400],
        colors.orange[400],
        colors.aqua[300],
        colors.aubergine[500],
        colors.sand[500],
      ],
      label: { color: theme.palette.common.white },
    },
    map: {
      itemStyle: {
        areaColor: theme.palette.neutral[100],
        borderColor: theme.palette.neutral[700],
        borderWidth: 0.5,
      },
      label: { color: theme.palette.common.black },
      emphasis: {
        itemStyle: {
          areaColor: theme.palette.warning[400],
          borderColor: theme.palette.neutral[700],
          borderWidth: 1,
        },
        label: { color: theme.palette.danger[700] },
      },
    },
    geo: {
      itemStyle: {
        areaColor: theme.palette.neutral[100],
        borderColor: theme.palette.neutral[700],
        borderWidth: 0.5,
      },
      label: { color: theme.palette.common.black },
      emphasis: {
        itemStyle: {
          areaColor: theme.palette.warning[400],
          borderColor: theme.palette.neutral[700],
          borderWidth: 1,
        },
        label: { color: theme.palette.danger[700] },
      },
    },
    categoryAxis: {
      axisLine: {
        lineStyle: { onZero: false, show: false },
      },
      axisTick: {
        show: true,
        lineStyle: { color: theme.palette.neutral[300] },
      },
      axisLabel: {
        ...baseTextStyle,
        show: true,
        showMinLabel: true,
        showMaxLabel: true,
        hideOverlap: true,
      },
      splitLine: {
        show: true,
        lineStyle: { color: [theme.palette.neutral[800]] },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [colors.alphaWhite[200], theme.palette.neutral[200]],
        },
      },
    },
    valueAxis: {
      axisLine: {
        onZero: false,
        show: false,
      },
      axisTick: {
        show: true,
        lineStyle: { color: theme.palette.neutral[300] },
      },
      axisLabel: {
        ...baseTextStyle,
        showMinLabel: true,
        showMaxLabel: true,
        hideOverlap: true,
        show: true,
      },
      nameTextStyle: { ...baseTextStyle },
      splitLine: {
        show: true,
        lineStyle: { color: theme.palette.neutral[300] },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [colors.alphaWhite[200], theme.palette.neutral[200]],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: { color: theme.palette.neutral[800] },
      },
      axisTick: {
        show: true,
        lineStyle: { color: theme.palette.neutral[800] },
      },
      axisLabel: { show: true, color: theme.palette.neutral[800] },
      splitLine: {
        show: true,
        lineStyle: { color: [theme.palette.neutral[800]] },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [colors.alphaWhite[200], theme.palette.neutral[200]],
        },
      },
    },
    timeAxis: {
      axisLine: {
        onZero: false,
        show: false,
      },
      splitLine: { show: false },
      axisTick: {
        show: true,
        lineStyle: { color: theme.palette.neutral[300] },
      },
      axisLabel: {
        ...baseTextStyle,
        show: true,
        showMinLabel: true,
        showMaxLabel: true,
        hideOverlap: true,
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [colors.alphaWhite[200], theme.palette.neutral[200]],
        },
      },
      nameTextStyle: {
        ...baseTextStyle,
      },
    },
    toolbox: {
      iconStyle: { borderColor: theme.palette.neutral[800] },
      emphasis: { iconStyle: { borderColor: theme.palette.neutral[800] } },
    },
    legend: {
      textStyle: {
        ...baseTextStyle,
        color: theme.palette.neutral[800],
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: theme.palette.neutral[600],
          width: "1",
          type: "solid",
        },
        z: 1,
        crossStyle: { color: theme.palette.neutral[600], width: "1" },
      },
      textStyle: { color: theme.palette.neutral[800] },
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.neutral[300],
      shadowColor: "transparent",
      borderWidth: 0,
      extraCssText: "box-shadow: none;",
    },
    timeline: {
      lineStyle: { color: theme.palette.neutral[800], width: "2" },
      itemStyle: { color: theme.palette.neutral[800], borderWidth: 1 },
      controlStyle: {
        color: theme.palette.neutral[800],
        borderColor: theme.palette.neutral[800],
        borderWidth: 1,
      },
      checkpointStyle: {
        color: theme.palette.neutral[800],
        borderColor: theme.palette.common.white,
      },
      label: { color: theme.palette.neutral[800] },
      emphasis: {
        itemStyle: { color: theme.palette.neutral[800] },
        controlStyle: {
          color: theme.palette.neutral[800],
          borderColor: theme.palette.neutral[800],
          borderWidth: 1,
        },
        label: { color: theme.palette.neutral[800] },
      },
    },
    visualMap: {
      color: [
        theme.palette.danger[500],
        theme.palette.warning[500],
        theme.palette.success[500],
      ],
    },
    dataZoom: { handleSize: "undefined%", textStyle: {}, brushSelect: false },
    markPoint: {
      label: { color: theme.palette.common.white },
      emphasis: { label: { color: theme.palette.common.white } },
    },
  };
}

export default echartsTheme;
