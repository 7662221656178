import { useState } from "react";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";
import type { TopLevelFormatterParams } from "echarts/types/dist/shared.js";
import type { ComponentType } from "react";
import { ThemeWrapper } from "library-design-system";

export default function useTooltipContainer() {
  const [tooltipContainer] = useState(() => {
    const element = window.document.createElement("div");
    const root = createRoot(element);
    return { root, element };
  });

  /**
   * A formatter function that renders a react component in the tooltip
   * @param component The react component that will be rendered in the tooltip
   * @param param the param passed to the formatter
   * @param rest additional props to be passed to the component
   * @returns the HTML string of the component
   */
  function formatter<T extends Record<string, unknown>>(
    Component: ComponentType<{ param: TopLevelFormatterParams } & T>,
    param: TopLevelFormatterParams,
    rest: T = {} as T
  ): HTMLDivElement {
    flushSync(() => {
      tooltipContainer.root.render(
        <ThemeWrapper>
          <Component param={param} {...rest} />
        </ThemeWrapper>
      );
    });
    return tooltipContainer.element;
  }

  return formatter;
}
