import type { Theme } from "@mui/material";
import type { Components } from "@mui/material/styles";
import radius from "../../tokens/radius";
import colors from "../../tokens/colors";
import { fontSize } from "../../tokens/typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const baseInputStyles = (theme: Theme) => ({
  padding: 0,
  paddingInline: theme.spacing(2),
  borderRadius: radius.md,
  "& span.MuiAutocomplete-tag": {
    backgroundColor: "transparent",
  },
});

const tagStyles = (theme: Theme) => ({
  height: "auto",
  "& svg": {
    color: theme.palette.info.contrastText,
  },
});

const autocompleteCustomizations: Components<Theme> = {
  MuiAutocomplete: {
    defaultProps: {
      color: "info",
      limitTags: 2,
      slotProps: {
        chip: {
          deleteIcon: <FontAwesomeIcon icon={faXmark} />,
        },
      },
    },
    styleOverrides: {
      paper: ({ theme }) => ({
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        borderRadius: radius.sm,
        boxShadow: theme.shadows[10],
      }),
      listbox: ({ theme, ownerState }) => ({
        border: `1px solid ${colors.grey[300]}`,
        borderRadius: radius.sm,
        maxHeight: ownerState.size === "small" ? "260px" : "300px",
        "& .MuiAutocomplete-option": {
          minHeight: ownerState.size === "medium" ? "2.5rem" : "2rem",
        },
        "& .MuiAutocomplete-option[aria-selected='true'].Mui-focused, .MuiAutocomplete-option[aria-selected='true']":
          {
            backgroundColor: theme.palette.info.light,
          },
        "& .MuiAutocomplete-option:hover, .MuiAutocomplete-option.Mui-focused":
          {
            backgroundColor: theme.palette.info.light,
          },
      }),
      root: ({ theme, ownerState }) => {
        const commonStyles = {
          boxShadow: "none",
          outlineColor: "transparent",
        };

        const inputRootStyles = {
          minHeight: ownerState.size === "medium" ? "2.5rem" : "2rem",
          borderRadius: radius.md,
          "& .MuiAutocomplete-tagSizeSmall .MuiChip-deleteIcon": {
            width: "0.75rem",
            height: "0.75rem",
          },
          "& .MuiAutocomplete-tagSizeMedium .MuiChip-deleteIcon": {
            width: "0.875rem",
            height: "0.875rem",
          },
          "& .MuiAutocomplete-tag": {
            ...tagStyles(theme),
          },
        };

        const inputStyles = {
          paddingInline: 0,
          paddingBlock: theme.spacing(0.5),
        };

        const filledInputStyles = {
          ...baseInputStyles(theme),
          "& .MuiAutocomplete-tag": {
            ...tagStyles(theme),
            backgroundColor: theme.palette.info.light,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.info.main,
            "& svg": {
              color: theme.palette.info.light,
            },
          },
        };

        const outlinedInputStyles = {
          ...baseInputStyles(theme),
          "& .MuiAutocomplete-tag": {
            ...tagStyles(theme),
            backgroundColor: theme.palette.info.light,
          },
          "&.Mui-disabled": {
            borderColor: theme.palette.info.main,
            "& svg": {
              color: theme.palette.info.light,
            },
          },
        };

        const underlineInputStyles = {
          ...baseInputStyles(theme),
          "& .MuiAutocomplete-tag": {
            ...tagStyles(theme),
            backgroundColor: theme.palette.background.paper,
          },
          "&.Mui-focused": {
            border: `2px solid ${theme.palette.info.light}`,
          },
          "& .MuiIconButton-root": {
            "&:hover": {
              backgroundColor: theme.palette.common.white,
            },
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.info.main,
            "& svg": {
              color: theme.palette.info.light,
            },
          },
        };

        const multiAutocompleteStyles = {
          ...(ownerState.multiple === true && {
            "&.MuiAutocomplete-root": {
              "& .MuiAutocomplete-endAdornment": {
                position: "initial",
                display: "flex",
                transform: "unset",
              },
              "& .MuiInputBase-root.MuiAutocomplete-inputRoot": {
                flexWrap: "nowrap",
                overflowX: "scroll",
                paddingRight: theme.spacing(2),
                "&::-webkit-scrollbar": { display: "none" },
                scrollbarWidth: "none",
              },
            },
          }),
        };

        return {
          ...commonStyles,
          ...multiAutocompleteStyles,
          "& .MuiAutocomplete-inputRoot": inputRootStyles,
          "& .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input, .MuiOutlinedInput-root.MuiInputBase-sizeSmall, .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input":
            inputStyles,
          "& .MuiFilledInput-root .MuiFilledInput-input, .MuiOutlinedInput-root .MuiAutocomplete-input, MuiInputBase-input":
            {
              paddingInline: 0,
            },
          "& .MuiFilledInput-root": filledInputStyles,
          "& .MuiOutlinedInput-root": outlinedInputStyles,
          "& .MuiInput-underline": underlineInputStyles,
          "&.MuiAutocomplete-root .MuiInputBase-sizeSmall": {
            fontSize: fontSize.sm,
            ...baseInputStyles(theme),
          },
          "& .MuiIconButton-root": {
            "&:hover": {
              backgroundColor: theme.palette.common.white,
            },
          },
          "& .Mui-disabled": {
            "& svg": {
              color: theme.palette.info.light,
            },
          },
        };
      },
    },
  },
};

export default autocompleteCustomizations;
