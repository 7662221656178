import * as echarts from "echarts/core";
import type {
  CustomSeriesRenderItemAPI,
  CustomSeriesRenderItemParams,
  CustomSeriesRenderItemReturn,
} from "echarts";
import type { Theme } from "@mui/joy";

type Cartesian2DCoordSys = {
  type: "cartesian2d";
  x: number;
  y: number;
  width: number;
  height: number;
};

export const renderItem = (
  params: CustomSeriesRenderItemParams,
  api: CustomSeriesRenderItemAPI,
  theme: Theme
): CustomSeriesRenderItemReturn => {
  const categoryIndex = api.value(0);
  const startX = api.coord([api.value(1), categoryIndex]);
  const endX = api.coord([api.value(2), categoryIndex]);
  const facilityName = api.value(9).toString();
  const hoverColor = api.value(10).toString();
  const operationColor = api.value(5).toString();
  const operationTitle = api.value(4).toString();
  const hasLeftNeighbor = api.value(7);
  const hasRightNeighbor = api.value(8);
  const isDisabled = api.value(12);

  const radiusWithoutPx = theme.radius.sm.replace("px", "");
  const heightExternalShape = 42;
  const heightInternalShape = 30;

  const { coordSys } = params as { coordSys: unknown } as {
    coordSys: Cartesian2DCoordSys;
  };

  const orientationObject = {
    x: coordSys.x,
    y: coordSys.y,
    width: coordSys.width,
    height: coordSys.height,
  };

  const rectShape = echarts.graphic.clipRectByRect(
    {
      x: startX[0],
      y: startX[1] - heightInternalShape / 2 - 1.5,
      width: endX[0] - startX[0],
      height: heightInternalShape,
    },
    orientationObject
  );

  if (facilityName) {
    return {
      type: "text",
      silent: true,
      style: {
        text: operationTitle,
        x: 15,
        y: api.coord([0, categoryIndex])[1] - heightExternalShape / 2 + 16,
        fill:
          theme.palette.mode === "dark"
            ? theme.palette.neutral[200]
            : theme.palette.neutral[800],
        font: `${String(theme.fontWeight.md)} ${theme.fontSize.xs} Inter`,
      },
      emphasis: {
        z2: 9,
      },
      z2: 10,
    };
  }

  const disableColor =
    theme.palette.mode === "dark"
      ? theme.palette.neutral[600]
      : theme.palette.neutral[300];

  return (
    rectShape && {
      type: "group",
      children: [
        {
          type: "rect",
          shape: {
            x: coordSys.x,
            y: api.coord([0, categoryIndex])[1] - heightExternalShape / 2, // Vertically centered
            width: coordSys.width,
            height: heightExternalShape,
            r: Number(radiusWithoutPx),
          },
          style: {
            opacity: 1,
            fill:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[800]
                : theme.palette.neutral[200],
          },
          emphasis: {
            style: {
              lineWidth: 2,
              stroke:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[600]
                  : theme.palette.neutral[400],
              fill:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[800]
                  : theme.palette.neutral[200],
            },
          },
          z: 0,
          z2: 0,
          name: "external",
        },
        {
          type: "rect",
          info: {
            isDisabled: Boolean(isDisabled),
          },
          shape: {
            ...rectShape,
            r: [
              hasLeftNeighbor ? 0 : Number(radiusWithoutPx), // left radius
              hasRightNeighbor ? 0 : Number(radiusWithoutPx), // right radius
              hasRightNeighbor ? 0 : Number(radiusWithoutPx), // bottom right radius
              hasLeftNeighbor ? 0 : Number(radiusWithoutPx), // bottom left radius
            ],
            y: api.coord([0, categoryIndex])[1] - heightInternalShape / 2, // Vertically centered
            height: heightInternalShape, // Height of the internal rectangle
          },
          style: {
            fill: isDisabled ? disableColor : operationColor,
          },
          emphasis: {
            style: {
              fill: isDisabled ? disableColor : hoverColor,
            },
            z2: 11,
          },
          z: 11,
          z2: 11,
          name: "internal",
        },
        {
          type: "text",
          silent: true,
          style: {
            text: operationTitle,
            x: 15, //15 is the padding left
            y: rectShape.y + rectShape.height / 2 - 4, // Vertically center
            fill:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[200]
                : theme.palette.neutral[800],
            font: `${String(theme.fontWeight.sm)} ${theme.fontSize.sm} Inter`,
          },
          emphasis: {
            z2: 9,
          },
          z2: 10,
        },
      ],
    }
  );
};
