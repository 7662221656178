import type { EChartsOption } from "echarts";
import colors from "library-design-system/tokens/colors";
import { formatNumber } from "library-frontend-utils/helpers";
import { type TranslationsType } from "library-translations";
import TooltipFormatter from "./TooltipFormatter";
import type { ComponentProps } from "react";
import type WellboreTimeDepthGraph from "./WellboreTimeDepthGraph";
import type { translationStrings } from "./constants";
import type useTooltipContainer from "../../helpers/useTooltipContainer";

type Status = "PRT" | "NPT" | "WOW";

type MarkAreaItemStart = {
  xAxis: number;
  yAxis: number;
  itemStyle: { color: string; borderColor?: string; borderWidth?: number };
};

type MarkAreaItemEnd = { xAxis: number; yAxis?: number };

type MarkAreaItem = [MarkAreaItemStart, MarkAreaItemEnd];

type SelectionState = Record<Status, boolean>;

const STATUS_COLORS = {
  PRT: {
    background: colors["alphaLightGreen"][50],
    border: colors.lightGreen[400],
  },
  NPT: { background: colors["alphaOrange"][300], border: colors.orange[400] },
  WOW: { background: colors["alphaPurple"][300], border: colors.purple[500] },
};

const colorCategories = [
  "alphaLightGreen",
  "alphaOrange",
  "alphaPurple",
] as const;
const colorShades = [800, 600, 400] as const;
type DefaultColorShades = (typeof colorShades)[number];
type ColorShades = Record<DefaultColorShades, string>;

const colorsArray: string[] = colorCategories.flatMap((category) =>
  colorShades.map((shade) => (colors[category] as ColorShades)[shade])
);

export const getFilteredMarkAreaData = (
  actual: ComponentProps<typeof WellboreTimeDepthGraph>["actual"] | undefined,
  selectionState: SelectionState,
  heightPercent: number
): { backgroundData: MarkAreaItem[]; borderData: MarkAreaItem[] } => {
  if (!actual) return { backgroundData: [], borderData: [] };

  const backgroundData: MarkAreaItem[] = [];
  const borderData: MarkAreaItem[] = [];

  // Find maximum depth
  const maxDepth = Math.max(0, ...actual.map(([, depth]) => depth || 0)) * 1.5;

  // Create mark areas for each segment
  actual.slice(0, -1).forEach(([date, , , , status], index) => {
    if (!selectionState[status]) return;

    const nextPoint = actual[index + 1][0];
    const { background: color, border: borderColor } = STATUS_COLORS[status];

    const currentHeight = maxDepth * heightPercent;

    backgroundData.push([
      {
        xAxis: date,
        yAxis: 0, // Start from 0
        itemStyle: { color },
      },
      {
        xAxis: nextPoint,
        yAxis: currentHeight, // End at maxDepth
      },
    ]);

    borderData.push([
      {
        xAxis: date,
        yAxis: 0,
        itemStyle: { color, borderColor, borderWidth: 1 },
      },
      { xAxis: nextPoint, yAxis: 0 },
    ]);
  });

  return { backgroundData, borderData };
};

export const getChartOptions = ({
  actual,
  planned,
  selection,
  colorScheme,
  t,
  tooltipContainer,
  heightPercent,
}: {
  actual: ComponentProps<typeof WellboreTimeDepthGraph>["actual"];
  planned: ComponentProps<typeof WellboreTimeDepthGraph>["planned"];
  selection: SelectionState;
  colorScheme: string;
  t: TranslationsType<typeof translationStrings>;
  tooltipContainer: ReturnType<typeof useTooltipContainer>;
  heightPercent: number;
}): EChartsOption => {
  if (!actual.length) return {};

  const seriesData = actual.map(
    ([date, depth, timestamp, description, status]) => {
      return {
        value: [date, depth, timestamp, description, status],
        itemStyle: {
          color: STATUS_COLORS[status].background,
          borderColor: STATUS_COLORS[status].border,
          borderWidth: 1,
        },
      };
    }
  );

  const seriesMetadata = {
    actual: { title: t["Actual Depth"] },
    planned: { title: t["Planned Depth"] },
  };

  const labels: Record<Status, string> = {
    PRT: t["Productive time"],
    NPT: t["Non productive time"],
    WOW: t["Waiting on weather"],
  };

  const { backgroundData, borderData } = getFilteredMarkAreaData(
    actual,
    selection,
    heightPercent
  );
  const isDark = colorScheme === "dark";

  return {
    animation: false,
    color: colorsArray,
    tooltip: {
      trigger: "axis",
      borderWidth: 0,
      backgroundColor: "transparent",
      extraCssText: "box-shadow: none;",
      formatter: (params) =>
        tooltipContainer(TooltipFormatter, params, { labels }),
      axisPointer: {
        lineStyle: {
          color: isDark ? colors["grey"][400] : colors["grey"][600],
        },
      },
    },
    xAxis: {
      name: t["Days"],
      nameGap: 45,
      splitLine: { show: false },
      nameLocation: "middle",
      type: "value",
      axisLabel: {
        margin: 12,
        padding: [0, 20],
        formatter: (value: number) =>
          Number.isInteger(value)
            ? String(value)
            : formatNumber(Number(value), 1),
      },
      nameTextStyle: { padding: [20, 0, 0, 0] },
    },
    yAxis: {
      name: t["Depth (m)"],
      nameLocation: "start",
      nameTextStyle: { padding: [0, 20, 5, 0] },
      startValue: 0,
      inverse: true,
      min: 0,
      type: "value",
    },
    dataZoom: [
      { type: "inside" },
      {
        type: "slider",
        startValue: 0,
        filterMode: "empty",
        showDataShadow: false,
        showDetail: true,
        height: 20,
        bottom: 45,
        backgroundColor: isDark ? colors["grey"][800] : colors["grey"][100],
        fillerColor: isDark ? colors["grey"][800] : colors["grey"][300],
        borderColor: isDark ? colors["grey"][600] : colors["grey"][300],
        handleSize: "100%",
        handleStyle: {
          color: isDark ? colors["grey"][500] : colors["grey"][100],
          borderColor: colors["grey"][600],
          opacity: 1,
        },
        brushSelect: true,
        labelFormatter: (value) =>
          Number.isInteger(value)
            ? String(value)
            : formatNumber(Number(value), 1),
      },
    ],
    grid: { left: 45, right: 20, containLabel: true },
    legend: [
      {
        show: true,
        data: [seriesMetadata.actual.title, seriesMetadata.planned.title],
        itemStyle: { color: "transparent" },
        top: 0,
        right: 440,
        itemGap: 15,
        itemWidth: 20,
        itemHeight: 22,
        inactiveColor: "transparent",
        inactiveBorderWidth: 1,
        inactiveBorderColor: colors["grey"][300],
        selectedMode: false,
      },
      {
        show: true,
        data: [
          {
            name: labels.PRT,
            icon: "roundRect",
            itemStyle: {
              color: STATUS_COLORS.PRT.background,
              borderWidth: 1,
              borderColor: STATUS_COLORS.PRT.border,
            },
          },
          {
            name: labels.NPT,
            icon: "roundRect",
            itemStyle: {
              color: STATUS_COLORS.NPT.background,
              borderWidth: 1,
              borderColor: STATUS_COLORS.NPT.border,
            },
          },
          {
            name: labels.WOW,
            icon: "roundRect",
            itemStyle: {
              color: STATUS_COLORS.WOW.background,
              borderWidth: 1,
              borderColor: STATUS_COLORS.WOW.border,
            },
          },
        ],
        top: 0,
        right: 20,
        orient: "horizontal",
        itemGap: 15,
        itemWidth: 16,
        itemHeight: 16,
        selected: {
          [labels.PRT]: selection.PRT,
          [labels.NPT]: selection.NPT,
          [labels.WOW]: selection.WOW,
        },
        selectedMode: "multiple",
      },
    ],
    title: {
      top: 0,
      left: 0,
      textStyle: {
        fontWeight: 300,
        fontSize: 20,
        fontFamily: "Inter",
        lineHeight: 24,
        color: isDark ? colors["grey"][100] : colors["grey"][800],
      },
    },
    series: [
      // Planned depth line
      {
        data: planned,
        name: seriesMetadata.planned.title,
        type: "line",
        animation: false,
        lineStyle: {
          type: "dashed",
          color: isDark ? colors["grey"][100] : colors["grey"][900],
        },
        silent: true,
        emphasis: {
          itemStyle: {
            color: colors["grey"][100],
            borderColor: colors["grey"][900],
            borderWidth: 2,
          },
        },
      },
      // Actual depth line
      {
        data: seriesData,
        name: seriesMetadata.actual.title,
        animation: false,
        type: "line",
        lineStyle: {
          color: isDark ? colors["grey"][100] : colors["grey"][900],
        },
        silent: true,
        emphasis: {
          itemStyle: {
            color: colors["grey"][900],
            borderColor: colors["grey"][100],
            borderWidth: 2,
          },
        },
      },
      {
        name: labels.PRT,
        type: "custom",
        renderItem: () => {
          return { type: "rect", children: [] };
        },
        emphasis: {
          itemStyle: {
            color: colors["grey"][900],
            borderColor: colors["grey"][100],
            borderWidth: 2,
          },
        },
        silent: true,
      },
      {
        name: labels.NPT,
        type: "custom",
        renderItem: () => {
          return { type: "rect", children: [] };
        },
        silent: true,
        emphasis: {
          itemStyle: {
            color: colors["grey"][900],
            borderColor: colors["grey"][100],
            borderWidth: 2,
          },
        },
      },
      {
        name: labels.WOW,
        type: "custom",
        renderItem: () => {
          return { type: "rect", children: [] };
        },
        silent: true,
        emphasis: {
          itemStyle: {
            color: colors["grey"][900],
            borderColor: colors["grey"][100],
            borderWidth: 2,
          },
        },
      },
      // Background areas
      {
        type: "line",
        showSymbol: false,
        markArea: { silent: true, data: backgroundData },
      },
      // Border areas
      {
        type: "line",
        showSymbol: false,
        markArea: { silent: true, data: borderData },
      },
    ],
  };
};
