import { createColumnHelper, type Row } from "@tanstack/react-table";
import { createExpandableColumn, sortingIcon } from "./helpers";
import type {
  CostTrackingDataType,
  CostTrackingTableProps,
} from "./CostTrackingTable";
import StatusChip, { type StatusType } from "./StatusChip";
import {
  formatDateWithHours,
  formatNumber,
} from "library-frontend-utils/helpers";
import { Typography } from "@mui/joy";

const columnHelper = createColumnHelper<CostTrackingDataType>();

export const createColumns = (
  t: NonNullable<CostTrackingTableProps["translations"]>,
  onClickExpand: (row: Row<CostTrackingDataType>) => void,
  total: CostTrackingTableProps["total"],
  currency?: string
) => {
  const columns = [
    createExpandableColumn({
      columnId: "expander",
      headerLabel: "",
      onClickExpand,
    }),
    columnHelper.group({
      header: "Metadata",
      enableSorting: false,
      meta: {
        headerStyle: {
          textAlign: "left",
        },
        cellStyle: {},
      },
      columns: [
        columnHelper.accessor("taskDescription", {
          header: t["Activity"],
          footer: t["Total"],
          enableSorting: false,
          meta: {
            headerStyle: {
              minWidth: 300,
              borderLeft: "none",
            },
            cellStyle: {
              minWidth: 300,
            },
          },
        }),
        columnHelper.accessor("status", {
          header: t["Status"],
          cell: ({ getValue }) => (
            <StatusChip status={getValue() as StatusType} />
          ),
          enableSorting: false,
          meta: {
            disableWrapping: true,
            headerStyle: {
              minWidth: 120,
              borderRight:
                "1px solid var(--joy-palette-neutral-outlinedBorder)",
            },
            cellStyle: {
              minWidth: 120,
              whiteSpace: "nowrap",
            },
          },
        }),
      ],
    }),
    columnHelper.group({
      header: "Timestamps",
      enableSorting: false,
      meta: {
        headerStyle: {
          textAlign: "center",
          minWidth: 320,
        },
        cellStyle: {},
      },
      columns: [
        columnHelper.accessor("taskStartDate", {
          header: ({ column }) => (
            <Typography
              level="title-md"
              fontWeight="lg"
              endDecorator={sortingIcon(column.getIsSorted())}
            >
              {t["Start date"]}
            </Typography>
          ),
          footer: total?.taskStartDate
            ? formatDateWithHours(total.taskStartDate)
            : "",
          cell: ({ getValue }) => {
            const value = getValue() as string;
            return value ? formatDateWithHours(value) : "";
          },
          enableSorting: true,
          meta: {
            headerStyle: {
              minWidth: 160,
              maxWidth: 160,
            },
            cellStyle: {
              minWidth: 160,
              maxWidth: 160,
            },
          },
        }),
        columnHelper.accessor("taskEndDate", {
          header: ({ column }) => (
            <Typography
              level="title-md"
              fontWeight="lg"
              endDecorator={sortingIcon(column.getIsSorted())}
            >
              {t["End date"]}
            </Typography>
          ),
          footer: total?.taskEndDate
            ? formatDateWithHours(total.taskEndDate)
            : "",
          cell: ({ getValue }) => {
            const value = getValue() as string;
            return value ? formatDateWithHours(value) : "";
          },
          enableSorting: true,
          meta: {
            headerStyle: {
              minWidth: 160,
              borderRight:
                "1px solid var(--joy-palette-neutral-outlinedBorder)",
            },
            cellStyle: {
              minWidth: 160,
              whiteSpace: "nowrap",
            },
          },
        }),
      ],
    }),
    // Duration, days
    columnHelper.group({
      header: "Durations, Days",
      enableSorting: false,
      meta: {
        headerStyle: {
          textAlign: "center",
        },
        cellStyle: {
          textAlign: "right",
        },
      },
      columns: [
        columnHelper.accessor("dsvTime", {
          header: t["DSV Time"],
          footer: total?.dsvTime ? formatNumber(total.dsvTime, 1) : "",
          cell: ({ getValue }) => formatNumber(getValue<number>(), 1),
          enableSorting: false,
          meta: {
            headerStyle: {
              minWidth: 130,
              maxWidth: 130,
              textAlign: "right",
              borderRight:
                "1px solid var(--joy-palette-neutral-outlinedBorder)",
            },
            cellStyle: {
              minWidth: 130,
              maxWidth: 130,
              textAlign: "right",
              borderRight:
                "1px solid var(--joy-palette-neutral-outlinedBorder)",
            },
          },
        }),
        columnHelper.accessor("actualTime", {
          header: t["Actual time"],
          footer: total?.actualTime ? formatNumber(total.actualTime, 1) : "",
          cell: ({ getValue }) => formatNumber(getValue<number>(), 1),
          enableSorting: false,
          meta: {
            headerStyle: {
              minWidth: 130,
              textAlign: "right",
            },
            cellStyle: {
              minWidth: 130,
              textAlign: "right",
            },
          },
        }),
        columnHelper.accessor("plannedTime", {
          header: t["Planned time"],
          footer: total?.plannedTime ? formatNumber(total.plannedTime, 1) : "",
          cell: ({ getValue }) => formatNumber(getValue<number>(), 1),
          enableSorting: false,
          meta: {
            headerStyle: {
              minWidth: 130,
              textAlign: "right",
            },
            cellStyle: {
              minWidth: 130,
              textAlign: "right",
            },
          },
        }),
        columnHelper.accessor("plannedDaysAheadBehind", {
          header: ({ column }) => (
            <Typography
              level="title-md"
              fontWeight="lg"
              endDecorator={sortingIcon(column.getIsSorted())}
            >
              {t["Δ Days"]}
            </Typography>
          ),
          footer: () => {
            const value = total?.plannedDaysAheadBehind
              ? total.plannedDaysAheadBehind
              : 0;
            const color =
              value < 0
                ? "var(--joy-palette-success-softColor)"
                : "var(--joy-palette-danger-softColor)";

            return (
              <Typography
                level="title-md"
                fontWeight="lg"
                textColor={color}
                textAlign="right"
              >
                {formatNumber(value, 1, "always")}
              </Typography>
            );
          },
          cell: (info) => {
            const value: number = info.getValue() as number;
            const color =
              value < 0
                ? "var(--joy-palette-success-softColor)"
                : "var(--joy-palette-danger-softColor)";

            return (
              <Typography
                level="title-md"
                fontWeight="lg"
                textColor={color}
                textAlign="right"
              >
                {formatNumber(value, 1)}
              </Typography>
            );
          },
          enableSorting: true,
          meta: {
            headerStyle: {
              minWidth: 130,
              textAlign: "right",
              borderRight:
                "1px solid var(--joy-palette-neutral-outlinedBorder)",
            },
            cellStyle: {
              minWidth: 130,
              textAlign: "right",
            },
          },
        }),
      ],
    }),
    // Costs
    columnHelper.group({
      header: currency ? `Costs, ${currency}` : "Costs",
      enableSorting: false,
      meta: {
        headerStyle: {
          textAlign: "center",
        },
        cellStyle: {},
      },
      columns: [
        columnHelper.accessor("vowdEstimationCost", {
          header: t["Est. VoWD"],
          footer: total?.vowdEstimationCost
            ? formatNumber(total.vowdEstimationCost, 2)
            : "",
          cell: ({ getValue }) => formatNumber(getValue<number>(), 2),
          enableSorting: false,
          meta: {
            headerStyle: {
              minWidth: 130,
              maxWidth: 130,
              borderRadius: "none",
              textAlign: "right",
            },
            cellStyle: {
              minWidth: 130,
              maxWidth: 130,
              textAlign: "right",
            },
          },
        }),
        columnHelper.accessor("plannedAfeCost", {
          header: t["Planned AFE"],
          footer: total?.plannedAfeCost
            ? formatNumber(total.plannedAfeCost, 2, "always")
            : "",
          cell: ({ getValue }) => formatNumber(getValue<number>(), 2),
          enableSorting: false,
          meta: {
            headerStyle: {
              minWidth: 130,
              textAlign: "right",
            },
            cellStyle: {
              minWidth: 130,
              textAlign: "right",
            },
          },
        }),
        columnHelper.accessor("taskCost", {
          header: ({ column }) => (
            <Typography
              level="title-md"
              fontWeight="lg"
              endDecorator={sortingIcon(column.getIsSorted())}
            >
              {t["Δ Cost"]}
            </Typography>
          ),
          footer: () => {
            const value = total?.actualCost ? total.actualCost : 0;
            const color =
              value < 0
                ? "var(--joy-palette-success-softColor)"
                : "var(--joy-palette-danger-softColor)";

            return (
              <Typography
                level="title-md"
                fontWeight="lg"
                textColor={color}
                textAlign="right"
              >
                {formatNumber(value, 2, "always")}
              </Typography>
            );
          },
          cell: (info) => {
            const value: number = info.getValue() as number;
            const color =
              value < 0
                ? "var(--joy-palette-success-softColor)"
                : "var(--joy-palette-danger-softColor)";

            return (
              <Typography
                level="title-md"
                fontWeight="lg"
                textColor={color}
                textAlign="right"
              >
                {formatNumber(value, 2)}
              </Typography>
            );
          },
          enableSorting: true,
          meta: {
            headerStyle: {
              minWidth: 130,
              textAlign: "right",
              borderRight:
                "1px solid var(--joy-palette-neutral-outlinedBorder)",
            },
            cellStyle: {
              minWidth: 130,
              textAlign: "right",
              borderRight:
                "1px solid var(--joy-palette-neutral-outlinedBorder)",
            },
          },
        }),
        columnHelper.accessor("baselineCostPrediction", {
          header: t["Baseline Cost Prediction"],
          footer: total?.baselineCostPrediction
            ? formatNumber(total.baselineCostPrediction, 2, "always")
            : "",
          cell: ({ getValue }) => formatNumber(getValue<number>(), 2),
          enableSorting: false,
          meta: {
            headerStyle: {
              minWidth: 130,
              textAlign: "right",
            },
            cellStyle: {
              minWidth: 130,
              textAlign: "right",
            },
          },
        }),
        columnHelper.accessor("dsvCostPrediction", {
          header: t["DSV Cost Prediction"],
          footer: total?.dsvCostPrediction
            ? formatNumber(total.dsvCostPrediction, 2, "always")
            : "",
          cell: ({ getValue }) => formatNumber(getValue<number>(), 2),
          enableSorting: false,
          meta: {
            headerStyle: {
              minWidth: 130,
              textAlign: "right",
            },
            cellStyle: {
              minWidth: 130,
              textAlign: "right",
              borderRightWidth: 0,
            },
          },
        }),
      ],
    }),
  ];

  return columns;
};
