import type { Theme } from "@mui/material";
import colors from "../../tokens/colors";

export const colorVariant = (theme: Theme) => ({
  primary: {
    main: theme.palette.primary.main,
    hover: colors.blue[600],
    active: theme.palette.primary.dark,
    default: theme.palette.info.contrastText,
  },
  secondary: {
    main: theme.palette.secondary.main,
    hover: colors.purple[600],
    active: theme.palette.secondary.dark,
    default: theme.palette.info.contrastText,
  },
  info: {
    main: theme.palette.common.white,
    hover: theme.palette.info.main,
    active: theme.palette.info.light,
    default: theme.palette.info.contrastText,
  },
  error: {
    main: theme.palette.error.main,
    hover: colors.red[600],
    active: theme.palette.error.dark,
    default: theme.palette.info.contrastText,
  },
  success: {
    main: theme.palette.success.main,
    hover: colors.green[600],
    active: theme.palette.success.dark,
    default: theme.palette.info.contrastText,
  },
  warning: {
    main: theme.palette.warning.main,
    hover: colors.yellow[600],
    active: theme.palette.warning.dark,
    default: theme.palette.info.contrastText,
  },
  default: {
    main: theme.palette.common.white,
    hover: colors.grey[600],
    active: colors.grey[700],
    default: theme.palette.info.contrastText,
  },
  inherit: {
    main: theme.palette.common.white,
    hover: colors.grey[600],
    active: colors.grey[700],
    default: theme.palette.info.contrastText,
  },
});
