import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "chevron-down";
const width = 16;
const height = 16;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M8.01562 14.5C10.3281 14.5 12.4531 13.2812 13.6406 11.25C14.7969 9.25 14.7969 6.78125 13.6406 4.75C12.4531 2.75 10.3281 1.5 8.01562 1.5C5.67188 1.5 3.54688 2.75 2.35938 4.75C1.20312 6.78125 1.20312 9.25 2.35938 11.25C3.54688 13.2812 5.67188 14.5 8.01562 14.5ZM8.01562 0C10.8594 0 13.4844 1.53125 14.9219 4C16.3594 6.5 16.3594 9.53125 14.9219 12C13.4844 14.5 10.8594 16 8.01562 16C5.14062 16 2.51562 14.5 1.07812 12C-0.359375 9.53125 -0.359375 6.5 1.07812 4C2.51562 1.53125 5.14062 0 8.01562 0ZM4.23438 7.53125C3.92188 7.25 3.92188 6.78125 4.23438 6.5C4.51562 6.1875 4.98438 6.1875 5.29688 6.5L8.01562 9.21875L10.7344 6.5C11.0156 6.1875 11.4844 6.1875 11.7969 6.5C12.0781 6.78125 12.0781 7.25 11.7969 7.53125L8.51562 10.7812C8.23438 11.0938 7.76562 11.0938 7.48438 10.7812L4.23438 7.53125Z";
const prefix = "fac";

export const ChevronDownCircle: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(ChevronDownCircle);
