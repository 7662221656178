import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "close";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M16.7812 8.28125L13.0312 12.0312L16.75 15.75C17.0625 16.0312 17.0625 16.5 16.75 16.7812C16.4688 17.0938 16 17.0938 15.7188 16.7812L11.9688 13.0625L8.25 16.7812C7.96875 17.0938 7.5 17.0938 7.21875 16.7812C6.90625 16.5 6.90625 16.0312 7.21875 15.7188L10.9375 12L7.21875 8.28125C6.90625 8 6.90625 7.53125 7.21875 7.21875C7.5 6.9375 7.96875 6.9375 8.28125 7.21875L12 10.9688L15.7188 7.25C16 6.9375 16.4688 6.9375 16.7812 7.25C17.0625 7.53125 17.0625 8 16.7812 8.28125Z";
const prefix = "fac";

export const Close: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(Close);
