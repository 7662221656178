import type { Components, Theme } from "@mui/material/styles";

const getCommonListItemStyles = (theme: Theme) => ({
  minHeight: 40,
  padding: 0,
  gap: theme.spacing(2),
  paddingInline: theme.spacing(3),
  paddingBlock: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
});

const listCustomizations: Components<Theme> = {
  MuiListItem: {
    styleOverrides: {
      root: ({ theme }) => getCommonListItemStyles(theme),
    },
  },
  MuiListItemButton: {
    defaultProps: {
      color: "neutral",
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        ...getCommonListItemStyles(theme),
        color: theme.palette.text.primary,
        "&:hover": {
          backgroundColor: theme.palette.info.main,
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.info.light,
          "&:hover": {
            backgroundColor: theme.palette.info.main,
          },
        },
      }),
    },
  },
};

export default listCustomizations;
