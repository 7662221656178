import { isSameYear } from "date-fns";

export default function timeFormatter(
  value: number,
  startDate: Date,
  endDate: Date,
  previousDateRef: React.MutableRefObject<Date | null>
) {
  const date = new Date(value);
  const userLocale = navigator.language || "en-US";

  const formattedDate = new Intl.DateTimeFormat(userLocale, {
    month: "short",
    day: "numeric",
    year: "2-digit",
  }).format(date);

  const rangeCoversMultipleYears = !isSameYear(startDate, endDate);

  if (!previousDateRef.current) {
    previousDateRef.current = date;
    return formattedDate;
  }

  if (rangeCoversMultipleYears) {
    return formattedDate;
  }

  previousDateRef.current = date;
  return new Intl.DateTimeFormat(userLocale, {
    month: "short",
    day: "numeric",
  }).format(date);
}
