import type { Components, Theme } from "@mui/material/styles";
import { fontSize } from "../../tokens/typography";

const formHelperTextCustomizations: Components<Theme> = {
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: 0,
        fontSize: fontSize.sm,
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
      }),
    },
  },
};

export default formHelperTextCustomizations;
