import type { Row, Table } from "@tanstack/react-table";
import type { CostTrackingDataType } from "./CostTrackingTable";
import { mockWellboreCostTrackingResponse } from "./mocks";
import { getCellStyles } from "./helpers";
import { Skeleton, Typography } from "@mui/joy";

export function NestedRowLoader({
  row,
  table,
}: {
  row: Row<CostTrackingDataType>;
  table: Table<CostTrackingDataType>;
}) {
  const { getVisibleCells } = row;

  return (
    <>
      {mockWellboreCostTrackingResponse.slice(0, 3).map((_, index) => {
        const isLast =
          index === mockWellboreCostTrackingResponse.slice(0, 3).length - 1;

        return (
          <tr
            className={`nested-row ${isLast ? "last" : ""}`}
            key={`${row.original.id}-loader-${index.toString()}`}
          >
            {getVisibleCells().map(({ id, column }, cellIndex) => {
              const isExpanderColumn = column.id === "expander";
              return (
                <td
                  key={`${row.original.id}-${id}-td`}
                  style={getCellStyles(column, row, cellIndex, table)}
                >
                  {isExpanderColumn ? null : (
                    <Typography>
                      <Skeleton>Loading</Skeleton>
                    </Typography>
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}
