import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "nested-row-divider-last";
const width = 1;
const height = 64;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData = "M13.5 32H8C4 32 1 30 1 25C1 19.4 1 6 1 0";
const prefix = "fac";

export const NestedRowDividerLast: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(NestedRowDividerLast);
