import ReactEChartsCore from "echarts-for-react/lib/core";
import { type ComponentPropsWithRef, forwardRef } from "react";
import * as echarts from "echarts";
import type { EChartsReactProps } from "echarts-for-react";
import { SVGRenderer } from "echarts/renderers";
import { useColorScheme, useTheme } from "@mui/joy";
import echartsTheme from "./EChartsTheme";
import echartsDarkTheme from "./EChartsDarkTheme";
import isChromatic from "chromatic/isChromatic";

function EChartsRenderer(
  props: EChartsReactProps,
  ref: ComponentPropsWithRef<typeof ReactEChartsCore>["ref"]
) {
  const { mode } = useColorScheme();
  const theme = useTheme();
  echarts.registerTheme("default", echartsTheme(theme));
  echarts.registerTheme("dark", echartsDarkTheme(theme));
  // @ts-expect-error type is being incorrectly reported here
  if (props.opts?.renderer === "svg") echarts.use([SVGRenderer]);
  (props.option as { animation?: boolean }).animation = !isChromatic();

  return (
    <ReactEChartsCore
      theme={mode === "dark" ? "dark" : "default"}
      {...props}
      ref={ref}
    />
  );
}

const EChartsWrapper = forwardRef(EChartsRenderer);
export default EChartsWrapper;
