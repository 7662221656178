import type { Components, Theme } from "@mui/joy";

const checkboxCustomizations: Components<Theme> = {
  JoyCheckbox: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        gap: theme.spacing(1),
        ...(["lg", "md"].includes(ownerState.size || "") && {
          gap: theme.spacing(2),
        }),
      }),
      action: {
        "&.Mui-focusVisible": {
          outline: "none",
        },
      },
    },
  },
};

export default checkboxCustomizations;
