import type { Components, Theme } from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";
import { selectClasses } from "@mui/joy";

const selectCustomizations: Components<Theme> = {
  JoySelect: {
    defaultProps: {
      indicator: <KeyboardArrowDown />,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: theme.radius.md,
        padding: theme.spacing(0, 3),
        "--Select-gap": theme.spacing(2),
        gap: theme.spacing(2),
        boxShadow: "none",
        [`& .${selectClasses.indicator}`]: {
          transition: "0.2s",
          [`&.${selectClasses.expanded}`]: {
            transform: "rotate(-180deg)",
          },
        },
        "& *": {
          margin: 0,
        },
        "& .MuiSelect-startDecorator, & .MuiSelect-endDecorator": {
          width: "1.5rem",
          height: "1.5rem",
          color: "inherit",
          margin: 0,
          "& svg": {
            width: "100%",
            height: "100%",
          },
        },
        "& .MuiSelect-indicator": {
          marginInlineStart: 0,
        },
        ...(ownerState.variant === "outlined" && {
          backgroundColor: "transparent",
        }),
        ...(ownerState.variant === "solid" &&
          ownerState.color === "neutral" && {
            boxShadow: theme.vars.shadow.xs,
          }),
        ...(ownerState.size === "sm" && {
          "--Select-minHeight": "2rem",
          padding: theme.spacing(0, 2),
        }),
        ...(ownerState.size === "md" && {
          "--Select-minHeight": "2.5rem",
        }),
        ...(ownerState.size === "lg" && {
          "--Select-minHeight": "3rem",
        }),
      }),
      listbox: ({ theme, ownerState }) => ({
        ...(ownerState.variant === "soft" && {
          backgroundColor: theme.vars.palette.background.surface,
          border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
          "& .MuiOption-root": {
            backgroundColor: theme.vars.palette.background.surface,
          },
        }),
        ...(ownerState.variant === "solid" && {
          backgroundColor: theme.vars.palette.background.surface,
          border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
          "& .MuiOption-root.MuiOption-highlighted:not([aria-selected='true'])":
            {
              backgroundColor: `var(--joy-palette-${ownerState.color ?? "primary"}-plainHoverBg)`,
            },
          ".MuiOption-root[aria-selected='true']": {
            backgroundColor: `var(--joy-palette-${ownerState.color ?? "primary"}-outlinedActiveBg)`,
          },
          "& .MuiOption-root": {
            color: `var(--joy-palette-${ownerState.color ?? "primary"}-plainHoverColor)`,
            backgroundColor: theme.vars.palette.background.surface,
            "&:not([aria-selected='true']):hover": {
              backgroundColor: `var(--joy-palette-${ownerState.color ?? "primary"}-outlinedHoverBg)`,
            },
          },
        }),
        "--ListDivider-gap": theme.spacing(1),
        "--ListItem-paddingY": theme.spacing(1),
        ...(ownerState.size === "sm" && {
          "--ListItem-paddingX": theme.spacing(2),
          "--ListDivider-gap": theme.spacing(0.5),
        }),
        ...(ownerState.size === "md" && {
          "--ListItem-paddingX": theme.spacing(3),
        }),
        ...(ownerState.size === "lg" && {
          "--ListItem-paddingY": theme.spacing(2),
          "--ListItem-paddingX": theme.spacing(4),
        }),
      }),
    },
  },
};

export default selectCustomizations;
