import type { ComponentProps } from "react";
import type CostTrackingTable from "./CostTrackingTable";
import type { StatusType } from "./StatusChip";

const statuses: StatusType[] = ["completed", "progress", "cancel", "planned"];

const getStatusByIndex = (index: number): (typeof statuses)[number] => {
  return statuses[index % statuses.length];
};

export const mockWellboreCostTrackingResponse: NonNullable<
  ComponentProps<typeof CostTrackingTable>["costs"]
> = Array.from({ length: 20 }).map((_, index) => ({
  id: `id-${(index + 1).toString()}`,
  taskDescription: `Activity ${(index + 1).toString()}`,
  taskStartDate: new Date("2024-10-29T12:00:00Z"),
  taskEndDate: new Date("2024-10-29T12:00:00Z"),
  dsvTime: 2.54,
  plannedTime: 2.54,
  actualTime: 2.54,
  plannedDaysAheadBehind: index % 3 ? -0.98 : 1.54,
  plannedAfeCost: undefined,
  vowdEstimationCost: 100232100.03,
  taskCost: index % 6 ? 100232100.03 : -80232100.03,
  baselineCostPrediction: 100232100.03222,
  dsvCostPrediction: 100232100.03111,
  status: getStatusByIndex(index),
  hasNested: true,
}));

export const mockWellboreCostTrackingTotalResponse: ComponentProps<
  typeof CostTrackingTable
>["total"] = {
  taskStartDate: new Date("2024-10-29T12:00:00Z"),
  taskEndDate: new Date("2024-10-29T12:00:00Z"),
  actualCost: 385534384,
  actualTime: 34.21875000000001,
  baselineCostPrediction: 314595536.0004506,
  dsvCostPrediction: 314595536.0004506,
  dsvTime: 33.354166666666664,
  plannedAfeCost: 385534384,
  plannedDaysAheadBehind: -8.291666666666666,
  plannedTime: 42.510416666666664,
  vowdEstimationCost: 314595536.0004506,
};
