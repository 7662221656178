import type { Components, Switch, SwitchOwnerState, Theme } from "@mui/joy";
import type { ComponentProps } from "react";

const SWITCH_SIZES = {
  sm: {
    trackHeight: "1.25rem",
    thumbSize: "0.875rem",
    trackWidth: "2.125rem",
  },
  md: {
    trackHeight: "1.5rem",
    thumbSize: "1.125rem",
    trackWidth: "2.625rem",
  },
  lg: {
    trackHeight: "2rem",
    thumbSize: "1.625rem",
    trackWidth: "3.5rem",
  },
} as const;

const BASE_STYLES = {
  gap: "0.5rem",
  thumbShadow: "transparent",
};

const getSizeStyles = (size: ComponentProps<typeof Switch>["size"]) => {
  if (!size) {
    return {};
  }
  return {
    "--Switch-trackHeight": SWITCH_SIZES[size].trackHeight,
    "--Switch-thumbSize": SWITCH_SIZES[size].thumbSize,
    "--Switch-trackWidth": SWITCH_SIZES[size].trackWidth,
  };
};

const getVariantStyles = (theme: Theme, ownerState: SwitchOwnerState) => {
  const variantStyles = {
    solid: {
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.vars.palette.common.white,
      },
    },
    softAndSolid: {
      "& .MuiSwitch-track": {
        backgroundColor: `var(--joy-palette-neutral-softBg)`,
      },
      "&.Mui-checked": {
        "& .MuiSwitch-track": {
          backgroundColor:
            ownerState.color === "neutral"
              ? theme.vars.palette.neutral.softHoverBg
              : `var(--joy-palette-${ownerState.color ?? "primary"}-${ownerState.variant ?? "solid"}Bg)`,
        },
      },
    },
  };

  return {
    ...((ownerState.variant === "solid" ||
      (ownerState.variant === "soft" && ownerState.color === "neutral")) &&
      variantStyles.solid),
    ...((ownerState.variant === "soft" || ownerState.variant === "solid") &&
      variantStyles.softAndSolid),
  };
};

const getHoverStyles = (theme: Theme, ownerState: SwitchOwnerState) => {
  const getTrackBackgroundColor = () => {
    if (!ownerState.checked && ownerState.variant === "solid") {
      return theme.vars.palette.neutral.softHoverBg;
    }

    if (ownerState.color === "neutral") {
      return ownerState.checked
        ? theme.vars.palette.neutral.solidActiveBg
        : theme.vars.palette.neutral.softHoverBg;
    }

    return `var(--joy-palette-${ownerState.color ?? "primary"}-${ownerState.variant ?? "solid"}HoverBg)`;
  };

  return {
    "&:hover": {
      ".MuiSwitch-track": {
        backgroundColor: getTrackBackgroundColor(),
      },
    },
  };
};

const getDisabledStyles = (theme: Theme) => ({
  "&.Mui-disabled": {
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.vars.palette.neutral.softDisabledColor,
    },
  },
});

const switchCustomizations: Components<Theme> = {
  JoySwitch: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        "--Switch-gap": BASE_STYLES.gap,
        "--Switch-thumbShadow": BASE_STYLES.thumbShadow,
        "& .MuiSwitch-action input": {
          left: 0,
        },
        ...getSizeStyles(ownerState.size),
        ...getVariantStyles(theme, ownerState),
        ...getHoverStyles(theme, ownerState),
        ...getDisabledStyles(theme),
      }),
    },
  },
};

export default switchCustomizations;
