export const OPERATION_TYPES = [
  "Drilling",
  "Completion",
  "FormationEvaluation",
  "Moving",
  "Interruption",
  "Workover",
  "PlugAbandon",
  "NotAvailable",
] as const;

export const translationStrings = [
  "Loading...",
  "Completion",
  "Plug Abandon",
  "Drilling",
  "Workover",
  "Interruption",
  "Formation Evaluation",
  "Not Specified",
  "Moving",
  "Error",
  "Nothing here yet!",
  "It seems that there are currently no planned activities. They will be displayed here once scheduled.",
  "We encountered an issue while trying to display your activity schedule. Please refresh the page or try again later.",
] as const;
