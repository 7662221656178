import type { Wellbore } from "../types";

export default function generateChartHeight(wellbores: Wellbore[]) {
  const config = {
    row: {
      height: 42, // Base height of each row less padding
      padding: 8, // Total padding for each row (5px top + 5px bottom)
      minVisible: 1, // Minimum number of visible rows
      maxVisible: 8, // Maximum number of visible rows
    },
    legend: {
      height: 80, // Height reserved for the legend
    },
  };

  // Calculate total height per row (base height + padding)
  const rowTotalHeight = config.row.height + config.row.padding;

  // Calculate minimum chart height (enough for one row + legend)
  const minChartHeight =
    config.row.minVisible * rowTotalHeight + config.legend.height;

  // Calculate maximum chart height (8 rows + legend)
  const maxChartHeight =
    config.row.maxVisible * rowTotalHeight + config.legend.height;

  // Calculate dynamic height based on actual number of rows
  const dynamicHeight =
    wellbores.length * rowTotalHeight + config.legend.height;

  // First ensure we're not below minimum height, then cap at maximum height
  return Math.min(Math.max(dynamicHeight, minChartHeight), maxChartHeight);
}
