type DefaultColorShades =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

type ColorShades = Record<DefaultColorShades, `#${string}`>;

const brandColors = {
  grey: {
    50: "#FAFAFA",
    100: "#F5F5F5",
    200: "#E5E5E5",
    300: "#D4D4D4",
    400: "#A3A3A3",
    500: "#737373",
    600: "#525252",
    700: "#404040",
    800: "#262626",
    900: "#101010",
  },
  blue: {
    50: "#DBEAFE",
    100: "#BEDBFF",
    200: "#8EC5FF",
    300: "#51A2FF",
    400: "#2B7FFF",
    500: "#155DFC",
    600: "#1447E6",
    700: "#193CB8",
    800: "#1C398E",
    900: "#162456",
  },
  yellow: {
    50: "#FEF9C2",
    100: "#FFF085",
    200: "#FFDF20",
    300: "#FDC700",
    400: "#F0B100",
    500: "#D08700",
    600: "#A65F00",
    700: "#894B00",
    800: "#733E0A",
    900: "#432004",
  },
  green: {
    50: "#DCFCE7",
    100: "#B9F8CF",
    200: "#7BF1A8",
    300: "#05DF72",
    400: "#00C950",
    500: "#00A63E",
    600: "#008236",
    700: "#016630",
    800: "#0D542B",
    900: "#032E15",
  },
  red: {
    50: "#FFE2E2",
    100: "#FFC9C9",
    200: "#FFA2A2",
    300: "#FF6467",
    400: "#FB2C36",
    500: "#E7000B",
    600: "#C10007",
    700: "#9F0712",
    800: "#82181A",
    900: "#460809",
  },
} as const satisfies Record<string, ColorShades>;

const utilityColors = {
  lightGreen: {
    50: "#EBFAF3",
    100: "#CFF2E3",
    200: "#9FE5C7",
    300: "#6ED7AC",
    400: "#3ECA90",
    500: "#0EBD74",
    600: "#0B975D",
    700: "#087146",
    800: "#064C2E",
    900: "#042F1D",
  },
  lime: {
    50: "#ECFCCA",
    100: "#D8F999",
    200: "#BBF451",
    300: "#9AE600",
    400: "#7CCF00",
    500: "#5EA500",
    600: "#497D00",
    700: "#3C6300",
    800: "#35530E",
    900: "#192E03",
  },
  pink: {
    50: "#FCE7F3",
    100: "#FCCEE8",
    200: "#FDA5D5",
    300: "#FB64B6",
    400: "#F6339A",
    500: "#E60076",
    600: "#C6005C",
    700: "#A3004C",
    800: "#861043",
    900: "#510424",
  },
  orange: {
    50: "#FEF3C6",
    100: "#FEE685",
    200: "#FFD230",
    300: "#FFB900",
    400: "#FE9A00",
    500: "#E17100",
    600: "#BB4D00",
    700: "#973C00",
    800: "#7B3306",
    900: "#461901",
  },
  aqua: {
    50: "#CEFAFE",
    100: "#A2F4FD",
    200: "#53EAFD",
    300: "#00D3F2",
    400: "#00B8DB",
    500: "#0092B8",
    600: "#007595",
    700: "#005F78",
    800: "#104E64",
    900: "#053345",
  },
  aubergine: {
    50: "#F3ECF4",
    100: "#EADDEB",
    200: "#D5BBD7",
    300: "#C098C3",
    400: "#AB76AF",
    500: "#96549B",
    600: "#78437C",
    700: "#5A325D",
    800: "#3C223E",
    900: "#1E111F",
  },
  sand: {
    50: "#FAF6EF",
    100: "#F7EFE3",
    200: "#EFDFC7",
    300: "#E6CFAC",
    400: "#DEBF90",
    500: "#D6AF74",
    600: "#AB8C5D",
    700: "#806946",
    800: "#56462E",
    900: "#3B2A12",
  },
  moss: {
    50: "#F1F5F9",
    100: "#E2E8F0",
    200: "#CAD5E2",
    300: "#90A1B9",
    400: "#62748E",
    500: "#45556C",
    600: "#314158",
    700: "#1D293D",
    800: "#0F172B",
    900: "#020618",
  },
  purple: {
    50: "#EDE9FE",
    100: "#DDD6FF",
    200: "#C4B4FF",
    300: "#A684FF",
    400: "#8E51FF",
    500: "#7F22FE",
    600: "#7008E7",
    700: "#5D0EC0",
    800: "#4D179A",
    900: "#2F0D68",
  },
} as const satisfies Record<string, ColorShades>;

const alphaColors = {
  alphaBlack: {
    50: "#0000000D",
    100: "#0000001A",
    200: "#00000033",
    300: "#0000004D",
    400: "#00000066",
    500: "#00000080",
    600: "#00000099",
    700: "#000000B3",
    800: "#000000CC",
    900: "#000000E6",
  },
  alphaWhite: {
    50: "#FFFFFF0D",
    100: "#FFFFFF1A",
    200: "#FFFFFF33",
    300: "#FFFFFF4D",
    400: "#FFFFFF66",
    500: "#FFFFFF80",
    600: "#FFFFFF99",
    700: "#FFFFFFB3",
    800: "#FFFFFFCC",
    900: "#FFFFFFE6",
  },
  alphaBlue: {
    50: "#2B7FFF0D",
    100: "#2B7FFF1A",
    200: "#2B7FFF33",
    300: "#2B7FFF4D",
    400: "#2B7FFF66",
    500: "#2B7FFF80",
    600: "#2B7FFF99",
    700: "#2B7FFFB3",
    800: "#2B7FFFCC",
    900: "#2B7FFFE6",
  },
  alphaYellow: {
    50: "#F0B1000D",
    100: "#F0B1001A",
    200: "#F0B10033",
    300: "#F0B1004D",
    400: "#F0B10066",
    500: "#F0B10080",
    600: "#F0B10099",
    700: "#F0B100B3",
    800: "#F0B100CC",
    900: "#F0B100E6",
  },
  alphaRed: {
    50: "#fb2c360d",
    100: "#fb2c361a",
    200: "#fb2c3633",
    300: "#fb2c364d",
    400: "#fb2c3666",
    500: "#fb2c3680",
    600: "#fb2c3699",
    700: "#fb2c36b3",
    800: "#fb2c36cc",
    900: "#fb2c36e6",
  },
  alphaLightGreen: {
    50: "#00C9500D",
    100: "#00C9501A",
    200: "#00C95033",
    300: "#00C9504D",
    400: "#00C95066",
    500: "#00C95080",
    600: "#00C95099",
    700: "#00C950B3",
    800: "#00C950CC",
    900: "#00C950E6",
  },
  alphaDarkGreen: {
    50: "#00BC7D0D",
    100: "#00BC7D1A",
    200: "#00BC7D33",
    300: "#00BC7D4D",
    400: "#00BC7D66",
    500: "#00BC7D80",
    600: "#00BC7D99",
    700: "#00BC7DB3",
    800: "#00BC7DCC",
    900: "#00BC7DE6",
  },
  alphaLime: {
    50: "#7CCF000D",
    100: "#7CCF001A",
    200: "#7CCF0033",
    300: "#7CCF004D",
    400: "#7CCF0066",
    500: "#7CCF0080",
    600: "#7CCF0099",
    700: "#7CCF00B3",
    800: "#7CCF00CC",
    900: "#7CCF00E6",
  },
  alphaPink: {
    50: "#F6339A0D",
    100: "#F6339A1A",
    200: "#F6339A33",
    300: "#F6339A4D",
    400: "#F6339A66",
    500: "#F6339A80",
    600: "#F6339A99",
    700: "#F6339AB3",
    800: "#F6339ACC",
    900: "#F6339AE6",
  },
  alphaOrange: {
    50: "#FE9A000D",
    100: "#FE9A001A",
    200: "#FE9A0033",
    300: "#FE9A004D",
    400: "#FE9A0066",
    500: "#FE9A0080",
    600: "#FE9A0099",
    700: "#FE9A00B3",
    800: "#FE9A00CC",
    900: "#FE9A00E6",
  },
  alphaAqua: {
    50: "#00B8DB0D",
    100: "#00B8DB1A",
    200: "#00B8DB33",
    300: "#00B8DB4D",
    400: "#00B8DB66",
    500: "#00B8DB80",
    600: "#00B8DB99",
    700: "#00B8DBB3",
    800: "#00B8DBCC",
    900: "#00B8DBE6",
  },
  alphaAubergine: {
    50: "#96549B0D",
    100: "#96549B1A",
    200: "#96549B33",
    300: "#96549B4D",
    400: "#96549B66",
    500: "#96549B80",
    600: "#96549B99",
    700: "#96549BB2",
    800: "#96549BCC",
    900: "#96549BE5",
  },
  alphaSand: {
    50: "#D6AF740D",
    100: "#D6AF741A",
    200: "#D6AF7433",
    300: "#D6AF744D",
    400: "#D6AF7466",
    500: "#D6AF7480",
    600: "#D6AF7499",
    700: "#D6AF74B2",
    800: "#D6AF74CC",
    900: "#D6AF74E5",
  },
  alphaMoss: {
    50: "#AECCCA0D",
    100: "#AECCCA1A",
    200: "#AECCCA33",
    300: "#AECCCA4D",
    400: "#AECCCA66",
    500: "#AECCCA80",
    600: "#AECCCA99",
    700: "#AECCCAB2",
    800: "#AECCCACC",
    900: "#AECCCAE5",
  },
  alphaPurple: {
    50: "#8E51FF0D",
    100: "#8E51FF1A",
    200: "#8E51FF33",
    300: "#8E51FF4D",
    400: "#8E51FF66",
    500: "#8E51FF80",
    600: "#8E51FF99",
    700: "#8E51FFB3",
    800: "#8E51FFCC",
    900: "#8E51FFE6",
  },
} as const satisfies Record<string, ColorShades>;

const colors = {
  ...brandColors,
  ...utilityColors,
  ...alphaColors,
} as const;

export default colors;
