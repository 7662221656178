import React from "react";
import { AutocompleteListbox, Button, Grid, Stack } from "@mui/joy";
import type { AutocompleteListboxProps } from "@mui/joy";
import { RichTreeView, type TreeItem2Props } from "@mui/x-tree-view";
import { CustomTreeItem } from "./CustomTreeItem";
import { getItemsWithParents } from "./helpers";
import type { TreeViewBaseItem } from "@mui/x-tree-view";

interface FilterMenuProps extends AutocompleteListboxProps {
  categories: string[];
  open: boolean;
  treeViewRef: React.ComponentProps<typeof RichTreeView>["apiRef"];
  tempSelectedFilters: string[];
  handleTreeItemClick: (
    checked: boolean,
    itemId: string,
    visibleItems: TreeViewBaseItem[]
  ) => void;
  allItems: TreeViewBaseItem[];
  disabledFilters: string[];
  filteredItems: TreeViewBaseItem[];
  handleClear: (event: React.MouseEvent<HTMLElement>) => void;
  handleConfirm: () => void;
  translations: Record<"Clear" | "Apply", string>;
}

export const FilterMenu = React.forwardRef<HTMLUListElement, FilterMenuProps>(
  (props, ref) => {
    const {
      open,
      treeViewRef,
      tempSelectedFilters,
      handleTreeItemClick,
      allItems,
      disabledFilters,
      filteredItems,
      handleClear,
      handleConfirm,
      translations,
      categories,
      ...rest
    } = props;

    if (!open) return undefined;

    const handleMouseDown = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <AutocompleteListbox
        {...rest}
        ref={ref}
        onMouseDown={handleMouseDown}
        sx={{
          maxHeight: 375,
          p: 2,
          position: "absolute",
          top: 65,
        }}
      >
        <Stack spacing={2} overflow="hidden" onMouseDown={handleMouseDown}>
          <RichTreeView
            role="tree"
            apiRef={treeViewRef}
            selectedItems={tempSelectedFilters}
            slots={{
              item: (props: TreeItem2Props) => (
                <CustomTreeItem
                  {...props}
                  onItemClick={handleTreeItemClick}
                  selectedItems={tempSelectedFilters}
                  visibleItems={filteredItems}
                  allItems={allItems}
                  categories={categories}
                />
              ),
            }}
            checkboxSelection
            multiSelect
            items={getItemsWithParents(filteredItems)}
            isItemDisabled={(item) => disabledFilters.includes(item.id)}
            defaultExpandedItems={categories}
            sx={{ maxHeight: 400, overflowY: "auto" }}
          />
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
            onMouseDown={handleMouseDown}
          >
            <Button
              color="neutral"
              variant="outlined"
              onClick={handleClear}
              onMouseDown={handleMouseDown}
            >
              {translations["Clear"]}
            </Button>
            <Button
              color="neutral"
              variant="soft"
              onClick={handleConfirm}
              onMouseDown={handleMouseDown}
            >
              {translations["Apply"]}
            </Button>
          </Grid>
        </Stack>
      </AutocompleteListbox>
    );
  }
);

FilterMenu.displayName = "FilterMenu";

export default FilterMenu;
