import type { TreeViewBaseItem } from "@mui/x-tree-view";
import type { UseTreeViewItemsPublicAPI } from "@mui/x-tree-view/internals/plugins/useTreeViewItems/useTreeViewItems.types";

interface TreeViewBaseItemWithParents extends TreeViewBaseItem {
  children?: TreeViewBaseItemWithParents[];
  parents?: string[];
}

export function getItemChildIds(item: TreeViewBaseItemWithParents): string[] {
  const ids: string[] = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    if (child.children) {
      ids.push(...getItemChildIds(child));
    }
  });
  return ids;
}

export function getItemsByType(
  allItems: TreeViewBaseItem[],
  type: string
): TreeViewBaseItem[] {
  return allItems.filter((item) => item.id.startsWith(`${type}:`));
}

export function getItemType(id: string, parents: string[]): string | null {
  // Remove 's' from the end of each parent to get the type
  const types = parents.map((parent) => parent.slice(0, -1));

  const pattern = new RegExp(`^(${types.join("|")}):`);
  const match = id.match(pattern);
  return match ? match[1] : null;
}

export function getItemParentIds(
  item: TreeViewBaseItemWithParents,
  api: UseTreeViewItemsPublicAPI<TreeViewBaseItemWithParents>
): string[] {
  const ids: string[] = [];
  item.parents?.forEach((parentId) => {
    ids.push(parentId);
    const parent = api.getItem(parentId);
    ids.push(...getItemParentIds(parent, api));
  });

  return ids;
}

export function getItemsWithParents(
  items: TreeViewBaseItem[],
  parents: string[] = []
): TreeViewBaseItemWithParents[] {
  return items.map((item) => {
    if (item.children) {
      const newParents = [...parents, item.id];
      return {
        ...item,
        parents,
        children: getItemsWithParents(item.children, newParents),
      };
    }

    return {
      ...item,
      parents,
    };
  });
}

function handleParentChecked(
  itemId: string,
  categoryItems: TreeViewBaseItem[],
  selectedFilters: string[]
): string[] {
  const itemsToAdd = categoryItems
    .map((item) => item.id)
    .filter((id) => !selectedFilters.includes(id));

  return itemsToAdd.length > 0
    ? [...selectedFilters, ...itemsToAdd, itemId]
    : [];
}

function handleParentUnchecked(
  type: string,
  selectedFilters: string[],
  filteredItems: TreeViewBaseItem[]
): string[] {
  // Get only the visible items of this type
  const visibleItemIds = getItemsByType(filteredItems, type).map(
    (item) => item.id
  );

  // Remove only the visible items and keep the others
  return selectedFilters.filter(
    (id) => !visibleItemIds.includes(id) && id !== `${type}s`
  );
}

function handleChildChecked(
  parents: string[],
  itemId: string,
  categoryId: string,
  allItems: TreeViewBaseItem[],
  selectedFilters: string[]
): string[] {
  const newFilters = [...selectedFilters, itemId];
  const itemType = getItemType(itemId, parents);
  if (!itemType) return newFilters;

  const typeItems = getItemsByType(allItems, itemType);

  return typeItems.every((item) => newFilters.includes(item.id))
    ? [...newFilters, categoryId]
    : newFilters;
}

export function handleSelectingLogic(
  parents: string[],
  checked: boolean,
  itemId: string,
  selectedFilters: string[],
  allItems: TreeViewBaseItem[],
  filteredItems: TreeViewBaseItem[]
): string[] {
  const isParent = parents.includes(itemId);
  const type = isParent ? itemId.slice(0, -1) : getItemType(itemId, parents);
  if (!type) return selectedFilters;

  const categoryId = `${type}s`;
  if (isParent && checked) {
    return handleParentChecked(
      itemId,
      getItemsByType(filteredItems, type),
      selectedFilters
    );
  }

  if (isParent && !checked) {
    return handleParentUnchecked(type, selectedFilters, filteredItems);
  }

  if (!isParent && checked) {
    return handleChildChecked(
      parents,
      itemId,
      categoryId,
      allItems,
      selectedFilters
    );
  }

  if (!isParent && !checked) {
    return selectedFilters.filter((id) => id !== itemId && id !== categoryId);
  }

  return selectedFilters;
}
