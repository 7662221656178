import type { CallbackDataParams } from "echarts/types/src/util/types.js";
import type { PerformanceBenchmarkChart } from "./PerformanceBenchmarkChart";
import type { ComponentProps } from "react";

export function generateSeriesData(data: { value: number }[]) {
  return data.map(({ value }, index) => [index + 0.5, value]);
}

export function roundToNextTen(num: number): number {
  return Math.ceil(num / 10) * 10;
}

export function labelFormatter(
  params: CallbackDataParams,
  benchmarkData: ComponentProps<
    typeof PerformanceBenchmarkChart
  >["benchmarkData"]
): string {
  const index = Math.floor(params.dataIndex);
  const labelText = benchmarkData[index]?.label || "";
  const maxLabelLength = 15;
  return labelText.length > maxLabelLength
    ? labelText.slice(0, maxLabelLength) + "…"
    : labelText;
}
