import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "task-completed";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12ZM16.0762 9.48016C16.3414 9.16195 16.2984 8.68903 15.9802 8.42385C15.6619 8.15868 15.189 8.20167 14.9239 8.51988L10.5 13.8285L9.07618 12.1199C8.81101 11.8017 8.33809 11.7587 8.01988 12.0239C7.70167 12.289 7.65868 12.7619 7.92385 13.0802L9.92385 15.4802C10.0663 15.6512 10.2774 15.75 10.5 15.75C10.7226 15.75 10.9337 15.6512 11.0762 15.4802L16.0762 9.48016Z";
const prefix = "fac";

export const TaskCompleted: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(TaskCompleted);
