import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
  type OnChangeFn,
  type SortingState,
} from "@tanstack/react-table";
import { Skeleton, Table, Typography } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import {
  getTableStyle,
  getHeaderStyles,
  getCellStyles,
  renderCellContent,
  getFooterCellStyles,
  getColumnHeaderStyle,
} from "./helpers";
import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import InfinityScrollButton from "../InfinityScrollButton/InfinityScrollButton";
import SectionErrorState from "../SectionErrorState/SectionErrorState";
import {
  Fragment,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  type CSSProperties,
} from "react";
import { createColumns } from "./columns";
import { NestedRowLoader } from "./NestedRowLoader";
import type { StatusType } from "./StatusChip";
import type {
  ExpandedState,
  Row,
  Table as TanstackTable,
} from "@tanstack/react-table";

const translationStrings = [
  "Error loading total",
  "Loading...",
  "No Data Available",
  "Error loading assets",
  "Pos",
  "Total Time (hr)",
  "DDR",
  "Load More...",
  "Actions",
  "Metadata",
  "Timestamps",
  "Duration, days",
  "Cost, NOK",
  "Activity",
  "Status",
  "Start date",
  "End date",
  "DSV Time",
  "Actual time",
  "Planned time",
  "Δ Days",
  "Est. VoWD",
  "Planned AFE",
  "Δ Cost",
  "Baseline Cost Prediction",
  "DSV Cost Prediction",
  "Total",
] as const;

export type CostTrackingDataType = {
  id: string;
  taskDescription: string;
  taskStartDate?: Date;
  taskEndDate?: Date;
  dsvTime?: number | null;
  plannedTime?: number | null;
  actualTime?: number | null;
  plannedDaysAheadBehind?: number | null;
  plannedAfeCost?: number | null;
  vowdEstimationCost?: number | null;
  taskCost?: number | null;
  baselineCostPrediction?: number | null;
  dsvCostPrediction?: number | null;
  status: StatusType;
  hasNested: boolean;
  nestedData?: CostTrackingDataType[];
};

type TableObserver = (table: TanstackTable<CostTrackingDataType>) => void;
type WellboreCostTrackingTableRef = {
  getTable: () => TanstackTable<CostTrackingDataType> | undefined;
  onTableUpdate: (callback: TableObserver) => () => void;
};

export type CostTrackingTableProps = {
  costs?: CostTrackingDataType[];
  total?:
    | ({ actualCost: number | null } & Omit<
        CostTrackingDataType,
        "taskDescription" | "id" | "taskCost" | "status" | "hasNested"
      >)
    | null;
  isLoading?: boolean;
  isError?: boolean;
  isEmpty?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  onBottomReached?: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<{
      results: CostTrackingDataType[];
    }>
  >;
  currency?: string;
  hasNextPage?: boolean;
  isLoadingNextPage?: boolean;
  extraHeader?: React.ReactNode;
  onOpenFeedbackClick: () => void;
  onAskForHelpClick: () => void;
  fetchNestedData: (row: CostTrackingDataType) => void;
  isFetchingNestedData?: boolean;
  isTotalLoading?: boolean;
  isTotalError?: boolean;
};

export default forwardRef<WellboreCostTrackingTableRef, CostTrackingTableProps>(
  function CostTrackingTable(
    {
      costs = [],
      total,
      translations,
      isLoading = false,
      isError = false,
      isEmpty = false,
      isFetchingNestedData,
      onSortingChange,
      sorting,
      onBottomReached,
      hasNextPage = true,
      isLoadingNextPage = false,
      currency,
      onOpenFeedbackClick,
      onAskForHelpClick,
      fetchNestedData,
      isTotalLoading,
      isTotalError,
    }: CostTrackingTableProps,
    ref
  ) {
    const t = { ...defaultTranslations(translationStrings), ...translations };
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [alreadyOpened, setAlreadyOpened] = useState<Record<string, boolean>>(
      {}
    );
    const [currentExpanded, setCurrentExpanded] = useState<string>("");
    const observers = useRef<Set<TableObserver>>(new Set());

    const onClickExpand = (row: Row<CostTrackingDataType>) => {
      const rowId = row.id;
      setCurrentExpanded(rowId);
      row.toggleExpanded();
      setAlreadyOpened((prev) => {
        return {
          ...prev,
          [rowId]: !alreadyOpened[rowId],
        };
      });

      if (!(rowId in alreadyOpened)) {
        fetchNestedData(row.original);
      }
    };

    const table = useReactTable({
      columns: createColumns(t, onClickExpand, total, currency),
      data: costs,
      getCoreRowModel: getCoreRowModel(),
      manualSorting: false,
      onSortingChange: onSortingChange,
      state: { sorting, expanded },
      onExpandedChange: setExpanded,
      getSubRows: (row) => row.nestedData,
      getRowCanExpand: (row) => row.original.hasNested,
      getExpandedRowModel: getExpandedRowModel(),
      getSortedRowModel: getSortedRowModel(),
    });

    useEffect(() => {
      observers.current.forEach((observer) => {
        observer(table);
      });
    }, [table, costs]);

    // Expose the table instance and the ability to subscribe to updates
    useImperativeHandle(
      ref,
      () => ({
        getTable: () => table,
        onTableUpdate: (callback: TableObserver) => {
          observers.current.add(callback);
          return () => {
            observers.current.delete(callback);
          };
        },
      }),
      [table]
    );

    const getRowCustomStyle = (row: Row<CostTrackingDataType>) => {
      const { original, depth } = row;
      const parentRow = row.getParentRow();

      let className = "";

      if (original.hasNested) {
        className += "nested-parent ";
      }

      if (depth > 0) {
        className += "nested-row ";
      }

      if (
        depth > 0 &&
        parentRow &&
        parentRow.subRows.length - 1 === row.index
      ) {
        className += "last ";
      }

      return className.trim();
    };

    const showLoadMore = !isLoading && !isEmpty && hasNextPage && !isError;

    const renderTable = (
      <Table
        borderAxis="x"
        stickyFooter={true}
        sx={{
          ...(isLoading && { overflow: "hidden" }),
          ...getTableStyle,
        }}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup, groupIndex) => (
            <Fragment key={headerGroup.id}>
              <tr>
                {headerGroup.headers.map((header, columnIndex) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      title={
                        typeof header.column.columnDef.header === "string"
                          ? header.column.columnDef.header
                          : ""
                      }
                      onClick={
                        header.column.getCanSort()
                          ? header.column.getToggleSortingHandler()
                          : undefined
                      }
                      style={getHeaderStyles(
                        header,
                        groupIndex,
                        columnIndex,
                        headerGroup.headers.length
                      )}
                    >
                      <Typography level="title-md" fontWeight="lg">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Typography>
                    </th>
                  );
                })}
              </tr>
            </Fragment>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const { id, getVisibleCells } = row;

            const showLoader = id in alreadyOpened && currentExpanded === id;
            const showNestedLoader = showLoader && isFetchingNestedData;

            return (
              <Fragment key={`row-${id}`}>
                <tr className={getRowCustomStyle(row)}>
                  {getVisibleCells().map(
                    ({ id, column, getContext }, index) => {
                      return (
                        <td
                          key={`cell-${id}`}
                          style={getCellStyles(column, row, index, table)}
                        >
                          {renderCellContent(
                            column,
                            row,
                            isError,
                            isEmpty,
                            isLoading,
                            getContext
                          )}
                        </td>
                      );
                    }
                  )}
                </tr>
                {showNestedLoader && (
                  <NestedRowLoader row={row} table={table} />
                )}
              </Fragment>
            );
          })}
          {showLoadMore && (
            <tr>
              <td colSpan={table.getVisibleFlatColumns().length}>
                <InfinityScrollButton
                  {...{
                    hasNextPage,
                    isLoading: isLoadingNextPage,
                    loadMoreText: t["Load More..."],
                    onTriggerFetchNextPage: onBottomReached,
                  }}
                />
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            {table.getFooterGroups().map((group) => (
              <Fragment key={group.id}>
                {group.headers
                  .filter((footer) => footer.subHeaders.length === 0)
                  .map((footer, index, filteredHeaders) => {
                    const isFirstCell = index === 0;
                    const isLastCell = index === filteredHeaders.length - 1;

                    const style = {
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "var(--joy-palette-background-body)",
                      ...(isFirstCell && { borderBottomLeftRadius: "8px" }),
                      ...(isLastCell && { borderBottomRightRadius: "8px" }),
                      ...getColumnHeaderStyle(footer),
                      ...getFooterCellStyles(index),
                    } as CSSProperties;

                    if (isTotalError) {
                      return (
                        <td key={footer.id} style={style}>
                          {index === 1 && (
                            <Typography
                              sx={{
                                color: (theme) =>
                                  theme.palette.warning.outlinedColor,
                              }}
                              level="title-md"
                              fontWeight="lg"
                            >
                              {t["Error loading total"]}
                            </Typography>
                          )}
                          {index > 2 && (
                            <Typography level="title-md" fontWeight="lg">
                              -
                            </Typography>
                          )}
                        </td>
                      );
                    }

                    return (
                      <td key={footer.id} style={style}>
                        {isTotalLoading && footer.column.id !== "expander" ? (
                          <Typography>
                            <Skeleton>{t["Loading..."]}</Skeleton>
                          </Typography>
                        ) : (
                          <Typography level="title-md" fontWeight="lg">
                            {flexRender(
                              footer.column.columnDef.footer,
                              footer.getContext()
                            )}
                          </Typography>
                        )}
                      </td>
                    );
                  })}
              </Fragment>
            ))}
          </tr>
        </tfoot>
      </Table>
    );

    return (
      <SectionErrorState
        isError={isError}
        isEmpty={!isLoading && isEmpty}
        onOpenFeedbackClick={onOpenFeedbackClick}
        onAskForHelpClick={onAskForHelpClick}
        variant="soft"
      >
        {renderTable}
      </SectionErrorState>
    );
  }
);
