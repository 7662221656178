import { createBrowserRouter } from "react-router-dom";
import { ErrorPage, LoadingPage } from "library-ui-components/pages";

export const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    HydrateFallback: LoadingPage,
    async lazy() {
      const c = await import("./root");
      return { Component: c.default };
    },
    children: [
      {
        index: true,
        async lazy() {
          const c = await import("./home");
          return { Component: c.default };
        },
      },
      {
        path: "features",
        async lazy() {
          const c = await import("./features");
          return { Component: c.default };
        },
      },
      {
        path: "metrics",
        async lazy() {
          const c = await import("./metrics");
          return { Component: c.default };
        },
      },
    ],
  },
]);
