import * as echarts from "echarts/core";
import {
  forwardRef,
  useMemo,
  useRef,
  useEffect,
  useImperativeHandle,
  type ComponentProps,
} from "react";
import { EChartsWrapper } from "library-design-system";
import type { ECElementEvent, EChartsOption } from "echarts";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import { roundToNextTen, generateSeriesData, labelFormatter } from "./helpers";
import colors from "library-design-system/tokens/colors";
import { useColorScheme, useTheme } from "@mui/joy";
import useTooltipContainer from "../../helpers/useTooltipContainer";
import Tooltip from "./Tooltip";
import type EChartsReactCore from "echarts-for-react/lib/core";

const translationStrings = ["Loading..."] as const;

type TooltipProps = ComponentProps<typeof Tooltip>;

type BenchmarkData = {
  label: string;
  value: number;
  barColor?: string;
  lineColor?: string;
  tooltip?: TooltipProps;
};

type PercentileData = {
  label: string;
  value: number;
  textColor: string;
  bgColor: string;
};

type PerformanceBenchmarkChartProps = {
  benchmarkData: BenchmarkData[];
  percentileData?: PercentileData[];
  isLoading?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsWrapper>["opts"]
  >["renderer"];
  style?: React.CSSProperties;
  xAxisName?: string;
  disableInteractionEffects?: boolean;
};

type EChartsInstance = ReturnType<EChartsReactCore["getEchartsInstance"]>;

type EChartsRendererRef = {
  getEchartsInstance: () => EChartsInstance;
};

export function PerformanceBenchmarkChart(
  {
    benchmarkData = [],
    percentileData = [],
    xAxisName,
    disableInteractionEffects = true,
    isLoading,
    translations,
    style,
    renderer,
  }: PerformanceBenchmarkChartProps,
  forwardedRef: React.Ref<EChartsRendererRef>
) {
  const t = { ...defaultTranslations(translationStrings), ...translations };
  const theme = useTheme();
  const { mode } = useColorScheme();
  const tooltipContainer = useTooltipContainer();

  const localChartRef = useRef<EChartsReactCore>(null);
  useImperativeHandle(forwardedRef, () => ({
    getEchartsInstance: () => {
      if (!localChartRef.current) {
        throw new Error("ECharts instance is not available");
      }
      return localChartRef.current.getEchartsInstance();
    },
  }));

  // Track the first render
  const isFirstRender = useRef(true);
  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const options: EChartsOption = useMemo(() => {
    const seriesData = generateSeriesData(benchmarkData);
    const hasBarLabels =
      !xAxisName && benchmarkData.some(({ label }) => Boolean(label));
    // Set animation variables based on first render
    const animDuration = isFirstRender.current ? 400 : 0;
    const barMarkLineDelay = isFirstRender.current ? 400 : 0;
    const customMarkLineDelay = isFirstRender.current ? 800 : 0;

    return {
      xAxis: {
        type: "value",
        min: 0,
        max: benchmarkData.length,
        splitLine: { show: false },
        axisTick: { show: false },
        axisLine: {
          onZero: true,
          show: true,
          lineStyle: {
            color: mode === "light" ? colors.grey[300] : colors.grey[600],
          },
        },
        axisLabel: { show: false },
        name: xAxisName,
        nameLocation: "middle" /*  Only will appear if name is set */,
        nameGap: 8,
        nameTextStyle: {
          fontSize: theme.fontSize.sm,
          fontWeight: 300,
          color: mode === "light" ? colors.grey[600] : colors.grey[400],
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: roundToNextTen(benchmarkData[benchmarkData.length - 1].value),
        axisTick: { show: false },
        axisLine: { show: false },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            color: mode === "light" ? colors.grey[300] : colors.grey[600],
          },
        },
        axisLabel: {
          margin: 5,
          color: mode === "light" ? colors.grey[600] : colors.grey[400],
        },
      },
      grid: { left: 35, right: 8, top: 8, bottom: hasBarLabels ? "20%" : 23 },
      series: [
        {
          silent: disableInteractionEffects,
          data: seriesData.map((data, index) => ({
            value: data,
            itemStyle: {
              color:
                benchmarkData[index]?.barColor ||
                (mode === "light" ? colors.grey[200] : colors.grey[700]),
            },
          })),
          type: "bar",
          barCategoryGap: "0%",
          label: {
            show: xAxisName === undefined,
            position: "bottom",
            rotate: 270,
            distance: 5,
            formatter: (params) => labelFormatter(params, benchmarkData),
            color: mode === "light" ? colors.grey[600] : colors.grey[400],
            fontSize: theme.fontSize.xs,
            fontFamily: theme.fontFamily.body,
            align: "left",
            verticalAlign: "middle",
          },
          markLine: {
            silent: true,
            symbol: ["none", "none"],
            label: { show: false },
            data: seriesData.map(([index, value], i) => [
              {
                coord: [index - 0.5, value],
                lineStyle: {
                  width: 2,
                  type: "solid",
                  color:
                    benchmarkData[i]?.lineColor ||
                    (mode === "light" ? colors.grey[600] : colors.grey[400]),
                },
              },
              { coord: [index + 0.5, value] },
            ]),
            animationDelay: barMarkLineDelay,
            animationDuration: animDuration,
            animationDelayUpdate: 0,
          },
          animationDuration: animDuration,
          animationDelayUpdate: 0,
        },
        {
          silent: true,
          type: "custom",
          markLine: {
            silent: true,
            symbol: ["none", "none"],
            data: percentileData.map(
              ({ label, value, textColor, bgColor }) => ({
                name: label,
                yAxis: value,
                lineStyle: { color: bgColor },
                label: {
                  show: true,
                  formatter: "{b}",
                  position: "start" as const,
                  offset: [12.5, 0],
                  backgroundColor: bgColor,
                  borderRadius: 3,
                  textStyle: {
                    align: "center",
                    color: textColor,
                    fontFamily: theme.fontFamily.body,
                    fontSize: theme.fontSize.xs,
                    fontWeight: 300,
                    height: 15,
                    width: 23,
                  },
                },
              })
            ),
            animationDelay: customMarkLineDelay,
            animationDuration: animDuration,
            animationDelayUpdate: 0,
          },
        },
      ],
      tooltip: {
        trigger: "axis",
        triggerOn: "none",
        enterable: true,
        borderWidth: 0,
        backgroundColor: "transparent",
        extraCssText: "box-shadow: none;",
        confine: true,
        axisPointer: { type: "shadow" },
        formatter: (params) => {
          if (!Array.isArray(params)) return "";

          const tooltipProps = benchmarkData[params[0].dataIndex].tooltip;
          if (!tooltipProps) return "";

          return tooltipContainer<TooltipProps>(Tooltip, params, {
            ...tooltipProps,
            onMouseLeave: () => {
              const echartsInstance =
                localChartRef.current?.getEchartsInstance();
              if (!echartsInstance || disableInteractionEffects) return;

              echartsInstance.on("mouseover", (params) => {
                echartsInstance.dispatchAction({
                  type: "showTip",
                  seriesIndex: params.seriesIndex,
                  dataIndex: params.dataIndex,
                  alwaysShowContent: false,
                });
              });
            },
          });
        },
      },
    };
  }, [
    benchmarkData,
    disableInteractionEffects,
    mode,
    percentileData,
    theme.fontFamily.body,
    theme.fontSize.sm,
    theme.fontSize.xs,
    xAxisName,
    tooltipContainer,
  ]);

  useEffect(() => {
    const echartsInstance = localChartRef.current?.getEchartsInstance();
    if (!echartsInstance || disableInteractionEffects) return;

    const handleTooltip = (params: ECElementEvent) => {
      const isClickEvent = params.event?.type === "click";
      echartsInstance.dispatchAction({
        type: "showTip",
        seriesIndex: params.seriesIndex,
        dataIndex: params.dataIndex,
        alwaysShowContent: isClickEvent,
      });

      if (isClickEvent) echartsInstance.off("mouseover");
    };

    echartsInstance.on("mouseover", handleTooltip);
    echartsInstance.on("click", handleTooltip);

    return () => {
      echartsInstance.off("mouseover", handleTooltip);
      echartsInstance.off("click", handleTooltip);
    };
  }, [localChartRef, disableInteractionEffects]);

  return (
    <EChartsWrapper
      ref={localChartRef}
      notMerge={true}
      lazyUpdate={true}
      echarts={echarts}
      option={options}
      style={style || { height: "100%", width: "100%" }}
      loadingOption={{
        text: t["Loading..."],
        color: "gray",
        textColor: "black",
        maskColor: "rgba(255, 255, 255, 0.8)",
        showSpinner: true,
        spinnerRadius: 10,
        fontSize: 16,
        fontWeight: 300,
        fontFamily: "Inter",
      }}
      showLoading={isLoading}
      opts={{ renderer }}
    />
  );
}

export default forwardRef<EChartsRendererRef, PerformanceBenchmarkChartProps>(
  PerformanceBenchmarkChart
);
