import { getCurrentStackName } from "../infra";
import type { PropsWithChildren } from "react";
import { PostHogProvider } from "posthog-js/react";
import IdentifyUser from "./helpers/IdentifyUser";

const environment = getCurrentStackName();

type PostHogWrapperProps = PropsWithChildren<{
  /** The app can be WDW (Well Delivery Workspace) or  */
  app?: "WDW" | "DR";
  /** The tunnel address to send the events to. */
  tunnel?: string;
  /** Whether to allow anonymous users to be tracked. */
  allowAnonymous?: boolean;
}>;

const publicKeys = {
  WDW: "phc_YhRfS1oHSMs6juFRamGiCBFqkHxBNHk8VVnwmlUvtBh",
  DR: "phc_NO1j5AYWIH0Tz9BiJbExqCZ89dgjZGtoZrDwm2dWiqs",
};

const defaultTunnelAddress =
  environment === "local" ? "http://localhost:3001/mreport" : "/mreport";

export default function MetricsWrapper({
  app = "WDW",
  tunnel = defaultTunnelAddress,
  allowAnonymous,
  children,
}: PostHogWrapperProps) {
  return (
    <PostHogProvider
      apiKey={publicKeys[app]}
      options={{ api_host: tunnel, person_profiles: "identified_only" }}
    >
      {allowAnonymous ? children : <IdentifyUser>{children}</IdentifyUser>}
    </PostHogProvider>
  );
}
