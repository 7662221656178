import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2Icon,
  TreeItem2IconContainer,
  TreeItem2Provider,
  TreeItem2Root,
  type TreeViewBaseItem,
  type UseTreeItem2Parameters,
} from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import * as React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { getItemChildIds, getItemsByType } from "./helpers";

interface TreeViewBaseItemWithParents extends TreeViewBaseItem {
  children?: TreeViewBaseItemWithParents[];
  parents?: string[];
}

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {
  onItemClick: (
    checked: boolean,
    itemId: string,
    visibleItems: TreeViewBaseItem[]
  ) => void;
  selectedItems: string[];
  allItems: TreeViewBaseItem[];
  visibleItems: TreeViewBaseItem[];
  categories: string[];
}

export const CustomTreeItem = React.forwardRef(function CustomTreeItem2(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    onItemClick,
    selectedItems,
    visibleItems,
    allItems,
    categories,
    ...other
  } = props;

  const {
    getCheckboxProps,
    getContentProps,
    getGroupTransitionProps,
    getIconContainerProps,
    getLabelProps,
    getRootProps,
    publicAPI,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId) as TreeViewBaseItemWithParents;

  let indeterminate = false;
  let isChecked = false;

  if (categories.includes(itemId)) {
    const type = itemId.slice(0, -1); // Remove 's' from the end
    const typeItems = getItemsByType(allItems, type);
    const selectedTypeItems = typeItems.filter((item) =>
      selectedItems.includes(item.id)
    );

    isChecked =
      selectedTypeItems.length === typeItems.length && typeItems.length > 0;
    indeterminate =
      selectedTypeItems.length > 0 &&
      selectedTypeItems.length < typeItems.length;
  } else if (item.children?.length) {
    const allChildIds = getItemChildIds(item);
    const selectedChildIds = allChildIds.filter((childId) =>
      selectedItems.includes(childId)
    );

    isChecked =
      selectedChildIds.length === allChildIds.length && allChildIds.length > 0;
    indeterminate =
      selectedChildIds.length > 0 &&
      selectedChildIds.length < allChildIds.length;
  } else {
    isChecked = selectedItems.includes(itemId);
  }

  const handleExpand = (event: React.MouseEvent) => {
    if (item.children?.length) {
      event.preventDefault();
      event.stopPropagation();
      publicAPI.setItemExpansion(event, itemId, !status.expanded);
    }
  };

  const handleRowClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      target.closest(".MuiCheckbox-root") ||
      target.closest(".MuiTreeItem-iconContainer") ||
      target.closest(".MuiFormControlLabel-label")
    ) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    onItemClick(!isChecked, itemId, visibleItems);

    if (item.children?.length) {
      publicAPI.setItemExpansion(event, itemId, !status.expanded);
    }
  };

  const checkboxProps = getCheckboxProps();
  const {
    disabled: checkboxDisabled,
    tabIndex,
    ref: checkboxRef,
    onChange: checkboxOnChange,
  } = checkboxProps;

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root
        {...getRootProps(other)}
        aria-expanded={status.expanded}
        data-testid={itemId}
      >
        <TreeItem2Content
          {...getContentProps()}
          onClick={handleRowClick}
          sx={{ cursor: "pointer" }}
        >
          <TreeItem2IconContainer
            {...getIconContainerProps()}
            onClick={handleExpand}
          >
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <FormControlLabel
            htmlFor={id}
            aria-checked={indeterminate ? "mixed" : isChecked}
            aria-expanded={status.expanded}
            control={
              <Checkbox
                color="info"
                id={id}
                disabled={checkboxDisabled}
                tabIndex={tabIndex}
                ref={checkboxRef as React.RefObject<HTMLButtonElement>}
                checked={isChecked}
                onChange={(e) => {
                  const { checked } = e.target;
                  e.stopPropagation();
                  onItemClick(checked, itemId, visibleItems);
                  checkboxOnChange(e);
                }}
                indeterminate={indeterminate}
                aria-checked={indeterminate ? "mixed" : isChecked}
              />
            }
            label={
              <span
                onClick={(e) => {
                  e.stopPropagation();

                  onItemClick(!isChecked, itemId, visibleItems);

                  if (item.children?.length) {
                    handleExpand(e);
                  }
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                {getLabelProps().children}
              </span>
            }
          />
        </TreeItem2Content>
        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});
