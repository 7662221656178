import { Grid, Sheet, Skeleton, Table, Typography } from "@mui/joy";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { formatDate } from "library-frontend-utils/helpers";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import { useRef } from "react";
import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import InfinityScrollButton from "../InfinityScrollButton/InfinityScrollButton";
import FeedbackMessage from "../FeedbackMessage/FeedbackMessage";

const translationStrings = [
  "Error",
  "Loading...",
  "Load More...",
  "Nothing here yet!",
  "Select a wellbore to start.",
] as const;

type TableDataType = { startDate: Date; name: string };

type WellboreActivityTasksTableProps = {
  tasks?: TableDataType[];
  isLoading?: boolean;
  isError?: boolean;
  hasNextPage?: boolean;
  isLoadingNextPage?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  onFetchNextPage?: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<{
      results: TableDataType[];
    }>
  >;
};

const columnHelper =
  createColumnHelper<
    NonNullable<WellboreActivityTasksTableProps["tasks"]>[number]
  >();

const columns = [
  columnHelper.accessor("startDate", {
    id: "startDate",
    cell: ({ getValue }) => formatDate(getValue()),
  }),
  columnHelper.accessor("name", { id: "name" }),
];

export default function WellboreActivityTasksTable({
  tasks = [],
  onFetchNextPage: onTriggerFetchNextPage,
  isError,
  isLoading,
  hasNextPage = false,
  isLoadingNextPage = false,
  translations,
}: WellboreActivityTasksTableProps) {
  const t = { ...defaultTranslations(translationStrings), ...translations };
  const sheetRef = useRef<HTMLTableElement>(null);

  const table = useReactTable({
    columns,
    data: tasks,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  function Content() {
    if (isError) {
      return (
        <tr>
          <td>
            <Typography>{t["Error"]}</Typography>
          </td>
          <td>
            <Typography>{t["Error"]}</Typography>
          </td>
        </tr>
      );
    }

    if (isLoading) {
      return Array.from({ length: 20 }).map((_, index) => (
        <tr key={`loading${index.toString()}`}>
          <td>
            <Typography>
              <Skeleton sx={{ display: "inline-block", width: "100%" }}>
                {t["Loading..."]}
              </Skeleton>
            </Typography>
          </td>
          <td>
            <Typography>
              <Skeleton sx={{ display: "inline-block", width: "100%" }}>
                {t["Loading..."]}
              </Skeleton>
            </Typography>
          </td>
        </tr>
      ));
    }

    return (
      <>
        {table.getRowModel().rows.map(({ id, getVisibleCells }) => (
          <tr key={id}>
            {getVisibleCells().map(
              ({
                id,
                column: {
                  columnDef: { cell },
                },
                getContext,
              }) => (
                <td key={id}>{flexRender(cell, getContext())}</td>
              )
            )}
          </tr>
        ))}
        {tasks.length !== 0 && hasNextPage && (
          <tr>
            <td
              colSpan={table.getHeaderGroups()[0].headers.length}
              style={{ textAlign: "center" }}
            >
              <InfinityScrollButton
                {...{
                  hasNextPage,
                  onTriggerFetchNextPage,
                  isLoading: isLoadingNextPage,
                  loadMoreText: t["Load More..."],
                }}
              />
            </td>
          </tr>
        )}
      </>
    );
  }

  return !isLoading && !isError && tasks.length === 0 ? (
    <Grid container height={360} justifyContent="center">
      <FeedbackMessage
        title={t["Nothing here yet!"]}
        description={t["Select a wellbore to start."]}
      />
    </Grid>
  ) : (
    <Sheet
      ref={sheetRef}
      sx={{ overflowY: "auto", background: "transparent", height: 360 }}
      tabIndex={0}
    >
      <Table
        borderAxis="none"
        sx={{
          borderRadius: "sm",
          borderCollapse: "separate",
          borderSpacing: (theme) => theme.spacing(0, 2),
          mt: -2,
          pr: () =>
            sheetRef.current?.scrollHeight &&
            sheetRef.current.scrollHeight > sheetRef.current.clientHeight
              ? 2
              : 0,
          "--TableCell-dataBackground": "var(--joy-palette-background-body)",
          tableLayout: "auto",
          fontSize: "md",
          "& tbody > tr": {
            "& > td:first-of-type": {
              minWidth: "100px",
            },
            "& > td:last-of-type": {
              width: "100%",
            },
          },
        }}
      >
        <tbody>
          <Content />
        </tbody>
      </Table>
    </Sheet>
  );
}
