import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material/styles";
interface StoryHeaderProps {
  name: string;
  link?: string;
}

const CustomSeparator = styled("span")(({ theme }) => ({
  fontSize: "var(--joy-fontSize-xl3)",
  margin: theme.spacing(0, 2),
}));

export default function StoryHeader({ name, link }: StoryHeaderProps) {
  return (
    <>
      <Box pt={12} px={12} pb={8}>
        <Breadcrumbs
          separator={<CustomSeparator>/</CustomSeparator>}
          sx={{ gap: 5, p: 0 }}
        >
          {name.split("/").map((n) => (
            <Typography key={n} variant="h4" fontWeight="xs" my={8}>
              {n}
            </Typography>
          ))}
        </Breadcrumbs>
        {link && (
          <Button
            variant="contained"
            component="a"
            href={link}
            target="_blank"
            size="large"
            color="primary"
            endIcon={<FontAwesomeIcon icon={faBook} />}
          >
            Documentation
          </Button>
        )}
      </Box>
      <Box
        sx={{
          background:
            "linear-gradient(90deg, #0068B9 0%, #6CBF35 52.61%, #FF6900 101.02%)",
          height: 10,
        }}
      />
    </>
  );
}
