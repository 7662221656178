import { InformationSecurity } from "./InformationSecurity";

export const authority = <T extends string>(
  tenantId: T
): `https://login.microsoftonline.com/${T}` =>
  `https://login.microsoftonline.com/${tenantId}`;

export const apps = {
  t60DeveloperPortal: {
    clientId: "cbac3232-b3f4-4d1f-91ba-eaf75e2729f4",
    servicePrincipalId: "845b7cde-7446-4c2d-8d54-c63e3d2a6f1c",
    company: "t60",
    tenantId: "c727d6d4-5305-4ace-87b4-188018f3b983",
    authority: authority("c727d6d4-5305-4ace-87b4-188018f3b983"),
    roles: [
      { id: "1db8aac9-7409-4f7b-b4c9-c4e5ad151560", name: "developer" },
      { id: "09e675c8-73e8-4166-889b-cb4701880d28", name: "infra" },
    ],
    securityLevel: {
      developer: InformationSecurity.Restricted,
      infra: InformationSecurity.Confidential,
    },
  },
  digitalSolutionsPlatformT60Dev: {
    clientId: "eb4bdea9-3313-4ed7-97d9-68a4277a8e5e",
    servicePrincipalId: "c95f3820-8565-4211-aa4b-765b388a89cf",
    company: "t60",
    tenantId: "c727d6d4-5305-4ace-87b4-188018f3b983",
    authority: authority("c727d6d4-5305-4ace-87b4-188018f3b983"),
    roles: [
      { id: "0d02a576-c15e-4dd8-8e3d-36a5bd503f31", name: "basic" },
      { id: "cf439c9b-299d-433c-97de-929327d37853", name: "admin" },
      { id: "b834fe0e-b624-4ac5-a06b-ed63e8b6e804", name: "extended" },
      { id: "4e12ec51-70ab-4fc0-a32b-406e91fa0bc5", name: "machine" },
    ],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
  digitalSolutionsPlatformT60Prod: {
    clientId: "64ccdc94-8b16-4c30-85b8-8472836689e9",
    servicePrincipalId: "5d742d1b-bccf-471d-92d2-72dcb5b8cf5a",
    company: "t60",
    tenantId: "c727d6d4-5305-4ace-87b4-188018f3b983",
    authority: authority("c727d6d4-5305-4ace-87b4-188018f3b983"),
    roles: [
      { id: "0d02a576-c15e-4dd8-8e3d-36a5bd503f31", name: "basic" },
      { id: "cf439c9b-299d-433c-97de-929327d37853", name: "admin" },
      { id: "b834fe0e-b624-4ac5-a06b-ed63e8b6e804", name: "extended" },
      { id: "4e12ec51-70ab-4fc0-a32b-406e91fa0bc5", name: "machine" },
    ],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
  odpDev: {
    clientId: "db805739-44d7-4aa2-a190-109b4907f2e4",
    servicePrincipalId: "a459ffc8-40a3-4a79-a2f8-b1716edbfc4b",
    company: "varenergi",
    tenantId: "77da4c42-ba77-462b-bb54-7f7ea57bd0a8",
    authority: authority("77da4c42-ba77-462b-bb54-7f7ea57bd0a8"),
    roles: [
      { id: "0d02a576-c15e-4dd8-8e3d-36a5bd503f31", name: "basic" },
      { id: "cf439c9b-299d-433c-97de-929327d37853", name: "admin" },
      { id: "b834fe0e-b624-4ac5-a06b-ed63e8b6e804", name: "extended" },
      { id: "4e12ec51-70ab-4fc0-a32b-406e91fa0bc5", name: "machine" },
    ],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
  odpProd: {
    clientId: "474b19dd-ef78-4558-97e5-6340865e5307",
    servicePrincipalId: "e7b116ea-b814-4caa-8da6-4050fb818854",
    company: "varenergi",
    tenantId: "77da4c42-ba77-462b-bb54-7f7ea57bd0a8",
    authority: authority("77da4c42-ba77-462b-bb54-7f7ea57bd0a8"),
    roles: [
      { id: "0d02a576-c15e-4dd8-8e3d-36a5bd503f31", name: "basic" },
      { id: "cf439c9b-299d-433c-97de-929327d37853", name: "admin" },
      { id: "b834fe0e-b624-4ac5-a06b-ed63e8b6e804", name: "extended" },
      { id: "4e12ec51-70ab-4fc0-a32b-406e91fa0bc5", name: "machine" },
    ],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
} as const;

export type AppName = keyof typeof apps;
