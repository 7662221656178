import type { Theme } from "@mui/material";
import type { Components } from "@mui/material/styles";
import { fontSize } from "../../tokens/typography";
import shadow from "../../tokens/shadow";
import radius from "../../tokens/radius";
import colors from "../../tokens/colors";

const textFieldCustomizations: Components<Theme> = {
  MuiTextField: {
    defaultProps: {
      color: "info",
      size: "medium",
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const commonInputBaseStyles = {
          height: "100%",
          paddingInline: theme.spacing(2),
          borderRadius: radius.md,
          gap: theme.spacing(1),
        };

        const sizeStyles = {
          small: {
            minHeight: "2rem",
            height: "100%",
            fontSize: fontSize.sm,
          },
          medium: {
            minHeight: "2.5rem",
            height: "100%",
            fontSize: fontSize.md,
          },
        };

        const variantStyles = {
          standard: {
            "& .MuiInputBase-root.MuiInput-underline": {
              padding: 0,
              paddingInline: theme.spacing(2),
              color:
                ownerState.color && ownerState.color !== "info"
                  ? theme.palette[ownerState.color]["dark"]
                  : theme.palette.info.contrastText,
              border: "2px solid transparent",
              backgroundColor:
                ownerState.color && ownerState.color !== "info"
                  ? theme.palette[ownerState.color]["light"]
                  : theme.palette.info.main,
              "& input::placeholder": {
                color:
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette[ownerState.color]["dark"]
                    : theme.palette.info.contrastText,
              },
              "&::before, &::after": {
                display: "none",
              },
              "&.Mui-focused": {
                border: `2px solid ${
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette[ownerState.color]["light"]
                    : theme.palette.info.light
                }`,
              },
              "&.Mui-error": {
                color: theme.palette.error.main,
                backgroundColor: theme.palette.error.light,
                "& input::placeholder": {
                  color: theme.palette.error.dark,
                },
                "&.Mui-focused": {
                  borderColor: theme.palette.error.main,
                },
              },
              "&.Mui-disabled": {
                backgroundColor: theme.palette.info.main,
                "&:hover": {
                  backgroundColor: theme.palette.info.main,
                },
              },
            },
            "& .MuiAutocomplete-endAdornment": {
              right: "9px",
            },
            "& svg": {
              color:
                ownerState.color && ownerState.color !== "info"
                  ? theme.palette[ownerState.color]["dark"]
                  : theme.palette.info.contrastText,
            },
          },
          outlined: {
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              paddingInline: theme.spacing(2),
              border: `2px solid ${
                ownerState.color && ownerState.color !== "info"
                  ? theme.palette[ownerState.color]["main"]
                  : theme.palette.info.light
              }`,
              color:
                ownerState.color && ownerState.color !== "info"
                  ? theme.palette[ownerState.color]["main"]
                  : theme.palette.info.contrastText,
              "& input::placeholder": {
                color:
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette[ownerState.color]["main"]
                    : theme.palette.info.contrastText,
              },
              "& fieldset": { display: "none" },
              "& svg": {
                color:
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette[ownerState.color]["main"]
                    : theme.palette.info.contrastText,
              },
              "&.Mui-disabled": {
                backgroundColor: "transparent",
                borderColor: theme.palette.info.main,
              },
              "&.Mui-error": {
                borderWidth: 1,
                borderColor: theme.palette.error.main,
                "& input::placeholder": {
                  color: theme.palette.error.dark,
                },
              },
            },
            "& .MuiInput-root": {
              backgroundColor: colors.grey[200],
              "&::before, &::after": {
                display: "none",
              },
            },
          },
          filled: {
            "& .MuiInputBase-root.MuiFilledInput-root": {
              "& input::placeholder": {
                color:
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette[ownerState.color]["contrastText"]
                    : theme.palette.info.dark,
                opacity: 0.7,
              },
              backgroundColor:
                ownerState.color && ownerState.color !== "info"
                  ? theme.palette[ownerState.color]["main"]
                  : theme.palette.common.white,
              boxShadow: shadow.xs,
              borderRadius: radius.md,
              border: "2px solid transparent",
              color:
                ownerState.color && ownerState.color !== "info"
                  ? theme.palette.common.white
                  : colors.grey[800],
              "& svg": {
                color:
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette.common.white
                    : colors.grey[800],
              },
              "&.Mui-disabled": {
                backgroundColor: theme.palette.info.main,
                "&:hover": {
                  backgroundColor: theme.palette.info.main,
                },
              },
              "&.Mui-focused": {
                border: `2px solid ${
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette[ownerState.color]["main"]
                    : theme.palette.info.light
                }`,
                backgroundColor:
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette[ownerState.color]["main"]
                    : theme.palette.common.white,
              },
              "&.Mui-error": {
                backgroundColor: theme.palette.error.main,
                "& input::placeholder": {
                  color: theme.palette.error.dark,
                },
                "&:hover": {
                  backgroundColor: theme.palette.error.main,
                },
                "&.Mui-focused": {
                  borderColor: theme.palette.error.main,
                },
              },
              "&::before, &::after": {
                display: "none",
              },
              "&:hover": {
                backgroundColor:
                  ownerState.color && ownerState.color !== "info"
                    ? theme.palette[ownerState.color]["main"]
                    : theme.palette.common.white,
              },
            },
          },
        };

        return {
          display: "flex",
          justifyContent: "center",
          "& .MuiInputBase-root": {
            ...commonInputBaseStyles,
            ...(ownerState.size === "small" && sizeStyles.small),
            ...(ownerState.size === "medium" && sizeStyles.medium),
            "& .MuiInputBase-input": {
              padding: 0,
              textOverflow: "ellipsis",
            },
            "& svg": {
              width: fontSize.lg,
              height: fontSize.lg,
            },
            "&.Mui-disabled.MuiInputBase-root": {
              "& svg": {
                color: theme.palette.info.light,
              },
            },
          },
          ...(ownerState.variant && variantStyles[ownerState.variant]),
        };
      },
    },
  },
};

export default textFieldCustomizations;
