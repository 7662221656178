import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "arrow-right";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M18.75 12.5625L13.25 17.8125C12.9375 18.0938 12.4688 18.0938 12.1875 17.7813C11.9062 17.4688 11.9062 17 12.2188 16.7188L16.375 12.75H5.75C5.3125 12.75 5 12.4375 5 12C5 11.5938 5.3125 11.25 5.75 11.25H16.375L12.2188 7.31253C11.9062 7.03128 11.9062 6.53128 12.1875 6.25003C12.4688 5.93753 12.9688 5.93753 13.25 6.21878L18.75 11.4688C18.9062 11.625 19 11.8125 19 12C19 12.2188 18.9062 12.4063 18.75 12.5625Z";
const prefix = "fac";

export const ArrowRight: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(ArrowRight);
