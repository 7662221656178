import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "cost";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M4 6.75C4 5.23122 5.23122 4 6.75 4H17.25C18.7688 4 20 5.23122 20 6.75V17.25C20 18.7688 18.7688 20 17.25 20H6.75C5.23122 20 4 18.7688 4 17.25V6.75ZM6.75 5.5C6.05964 5.5 5.5 6.05964 5.5 6.75V17.25C5.5 17.9404 6.05964 18.5 6.75 18.5H7.5V5.5H6.75ZM9 9V18.5H17.25C17.9404 18.5 18.5 17.9404 18.5 17.25V9H9ZM18.5 7.5H9V5.5H17.25C17.9404 5.5 18.5 6.05964 18.5 6.75V7.5Z";
const prefix = "fac";

export const DashboardWithSidebar: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(DashboardWithSidebar);
