import type { Components, Theme } from "@mui/material/styles";
import shadow from "../../tokens/shadow";
import radius from "../../tokens/radius";

const modalCustomizations: Components<Theme> = {
  MuiBackdrop: {
    styleOverrides: {
      root: {
        "&.MuiModal-backdrop": {
          backgroundColor: "rgba(41, 55, 70, 0.25)",
        },
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .modal-content": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: theme.palette.background.paper,
          boxShadow: shadow.xl,
          padding: theme.spacing(6),
          borderRadius: radius.lg,
          "&:focus": {
            outline: "none",
          },
          border: `1px solid ${theme.palette.info.light}`,
        },
      }),
    },
  },
};

export default modalCustomizations;
