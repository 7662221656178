import type { LineSeriesOption } from "echarts";

interface calculateDynamicValuesYAxisResult {
  interval: number;
  max: number;
}

export const calculateDynamicValuesYAxis = (
  series: LineSeriesOption[],
  selected?: Record<string, boolean>
): calculateDynamicValuesYAxisResult => {
  const hasSelectedSeries = selected
    ? Object.values(selected).some((isSelected) => isSelected)
    : true;

  const calculateValues = (seriesToUse: LineSeriesOption[]) => {
    const values = seriesToUse
      .flatMap((series) => {
        const originalData = series.data as (number | [Date, number])[];
        return originalData;
      })
      .map((item) => (typeof item === "number" ? item : item[1]));

    if (values.length === 0) return { interval: 1, max: 5 };

    const maxValue = Math.max(...values);
    const maxWithPadding = maxValue * 1.05;
    const magnitude = Math.floor(Math.log10(maxWithPadding));
    const base = Math.pow(10, magnitude);
    const normalizedMax = maxWithPadding / base;

    const interval = (() => {
      if (normalizedMax <= 1.5) return base / 2;
      if (normalizedMax <= 3.0) return base;
      if (normalizedMax <= 7.5) return base * 2;
      return base * 5;
    })();

    return {
      interval,
      max: Math.ceil(maxWithPadding / interval) * interval,
    };
  };

  const filteredSeries = hasSelectedSeries
    ? series.filter((s) => !selected || (s.name && selected[s.name]))
    : series;

  const result = calculateValues(filteredSeries);
  return result.max === 0 || isNaN(result.max)
    ? calculateValues(series)
    : result;
};
