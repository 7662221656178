import type { Components } from "@mui/material/styles";
import type { Theme } from "@mui/material";
import typography, {
  fontSize,
  fontWeight,
  lineHeight,
} from "../../tokens/typography";
import shadow from "../../tokens/shadow";
import colors from "../../tokens/colors";

const inputCustomizations: Components<Theme> = {
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        left: "10px",
        maxWidth: "fit-content",
        textOverflow: "inherit",
        fontSize: typography.fontSize.md,
        fontWeight: fontWeight.md,
        lineHeight: lineHeight.sm,
        color: theme.palette.text.secondary,
        "&.Mui-focused": {
          color: theme.palette.text.secondary,
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        lineHeight: 1,
        fontSize: fontSize.sm,
        fontWeight: fontWeight.md,
        textAlign: "left",
        top: "-5px",
        left: theme.spacing(2),
        "&.Mui-focused": {
          color: theme.palette.text.secondary,
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingInline: theme.spacing(2),
        gap: theme.spacing(1),
        "&.MuiInputBase-root": {
          marginTop: 0,
          "& .MuiSelect-icon": {
            right: theme.spacing(2),
          },
          "&:after": {
            borderBottom: "none",
          },
          "&.Mui-disabled": {
            pointerEvents: "none",
            "& .MuiSvgIcon-root": {
              color: theme.palette.action.disabled,
              opacity: 0.7,
            },
          },
        },
        "& .MuiInput-root": {
          backgroundColor: theme.palette.info.main,
        },
        "&.MuiOutlinedInput-root": {
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.info.light,
            },
          },
          "&.Mui-focused, .Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.info.light,
              borderWidth: 1,
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.info.light,
          },
        },
        "&.MuiFilledInput-root": {
          boxShadow: shadow.xs,
          backgroundColor: theme.palette.common.white,
          color: colors.grey[800],
          "& .MuiSvgIcon-root": {
            color: colors.grey[800],
          },
          "&:hover": {
            backgroundColor: colors.grey[100],
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.info.main,
            opacity: 0.7,
          },
          "&.Mui-focused": {
            backgroundColor: colors.grey[100],
          },
        },
        "&.MuiInput-underline": {
          backgroundColor: theme.palette.info.main,
          "&.Mui-disabled": {
            backgroundColor: theme.palette.info.main,
          },
        },
        "&:before": {
          display: "none",
        },
      }),
    },
  },
};

export default inputCustomizations;
