import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "sort-arrow-up-down";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M11.4697 5.46967C11.7626 5.17678 12.2374 5.17678 12.5303 5.46967L16.5303 9.46967C16.8232 9.76256 16.8232 10.2374 16.5303 10.5303C16.2374 10.8232 15.7626 10.8232 15.4697 10.5303L12 7.06066L8.53033 10.5303C8.23744 10.8232 7.76256 10.8232 7.46967 10.5303C7.17678 10.2374 7.17678 9.76256 7.46967 9.46967L11.4697 5.46967Z M11.4697 18.5303C11.7626 18.8232 12.2374 18.8232 12.5303 18.5303L16.5303 14.5303C16.8232 14.2374 16.8232 13.7626 16.5303 13.4697C16.2374 13.1768 15.7626 13.1768 15.4697 13.4697L12 16.9393L8.53033 13.4697C8.23744 13.1768 7.76256 13.1768 7.46967 13.4697C7.17678 13.7626 7.17678 14.2374 7.46967 14.5303L11.4697 18.5303Z ";
const prefix = "fac";

export const SortUpDown: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(SortUpDown);
