import type { Theme } from "@mui/material";
import colors from "../../tokens/colors";
import { DayCalendarSkeleton } from "@mui/x-date-pickers-pro";
import type { Components } from "@mui/material/styles";
import radius from "../../tokens/radius";
import typography, { fontWeight, lineHeight } from "../../tokens/typography";
import breakpoints from "../../tokens/breakpoints";

const pickerCustomizations: Components<Theme> = {
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      button: ({ theme }) => ({
        color: theme.palette.text.primary,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }),
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: ({ theme }) => ({
        position: "relative",
        "&:hover": {
          backgroundColor: "transparent",
          borderColor: theme.palette.text.secondary,
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.action.selected,
          color: theme.palette.action.hover,
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.info.contrastText,
          },
        },
        "&.MuiPickersDay-today": {
          border: "none",
          "&:after": {
            content: '" "',
            position: "absolute",
            bottom: 6,
            left: 0,
            right: 0,
            margin: "0 auto",
            height: 4,
            width: 4,
            borderRadius: radius.md,
            backgroundColor: theme.palette.info.contrastText,
          },
        },
      }),
    },
  },
  MuiDateRangePicker: {
    defaultProps: {
      format: "dd/MM/yyyy",
      renderLoading: () => <DayCalendarSkeleton />,
      desktopModeMediaQuery: `@media (min-width: ${breakpoints["sm"].toString()}px)`,
      slotProps: {
        fieldSeparator: {
          sx: {
            color: colors.grey[500],
            margin: 0,
            padding: 0,
          },
        },
        desktopPaper: {
          sx: (theme) => ({
            position: "relative",
            right: "13px",
            "& .MuiPickersLayout-actionBar > .MuiButtonBase-root": {
              color: theme.palette.text.primary,
              fontWeight: typography.fontWeight.md,
              fontSize: typography.fontSize.sm,
              boxShadow: theme.shadows[0],
              padding: "0 12px",
              height: "40px",
              borderRadius: "44px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            },
          }),
        },
      },
    },
  },
  MuiDateRangePickerDay: {
    styleOverrides: {
      root: {
        width: "40px",
        height: "40px",
      },
      rangeIntervalDayHighlight: ({ theme }) => ({
        backgroundColor: theme.palette.action.hover,
        margin: "1px 0",
        ":first-of-type": {
          borderTopLeftRadius: "50%",
          borderBottomLeftRadius: "50%",
        },
        ":last-of-type": {
          borderTopRightRadius: "50%",
          borderBottomRightRadius: "50%",
        },
      }),
      rangeIntervalDayHighlightStart: {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
      },
      rangeIntervalDayHighlightEnd: {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
      },
      outsideCurrentMonth: {
        backgroundColor: "transparent",
      },
      day: ({ theme }) => ({
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&.Mui-selected": {
          backgroundColor: theme.palette.action.selected,
          color: theme.palette.action.hover,
          "&:hover": {
            backgroundColor: "transparent",
            border: "none",
          },
          "&:focus": {
            backgroundColor: theme.palette.info.contrastText,
            color: theme.palette.action.hover,
          },
        },
      }),
    },
  },
  MuiDayCalendar: {
    styleOverrides: {
      header: {
        width: "280px",
      },
      weekContainer: {
        margin: 0,
        width: "280px",
      },
      slideTransition: {
        height: "255px !important",
        minHeight: "255px !important",
        width: "280px !important",
        minWidth: "280px !important",
      },
    },
  },
  MuiDateRangeCalendar: {
    styleOverrides: {
      root: {
        order: 1,
        "& .MuiTypography-root": {
          fontSize: typography.fontSize.md,
        },
        "& .MuiTypography-subtitle1": {
          fontWeight: 700,
        },
        "& .MuiDayCalendar-weekDayLabel": {
          fontWeight: 700,
          fontSize: typography.fontSize.sm,
          width: "40px",
          height: "40px",
          color: colors.grey[600],
        },
        "& .MuiPickersArrowSwitcher-root": {
          padding: 0,
        },
      },
      monthContainer: ({ theme }) => ({
        borderRight: "none !important",
        [theme.breakpoints.up("sm")]: {
          "&:first-of-type": {
            paddingRight: theme.spacing(4),
          },
          "&:last-of-type": {
            paddingLeft: theme.spacing(4),
          },
        },
      }),
    },
  },
  MuiMultiInputDateRangeField: {
    styleOverrides: {
      root: ({ theme }) => ({
        maxWidth: "340px",
        minWidth: "200px",
        borderRadius: radius.md,
        background: "transparent",
        position: "relative",
        border: `1px solid ${theme.palette.info.light}`,
        "&.MuiMultiInputDateRangeField-root": {
          "& .MuiInputBase-root": {
            paddingInline: 0,
          },
        },
        "& >:first-of-type ": {
          borderRight: `1px solid ${theme.palette.info.light}`,
        },
        "& >:last-child ": {
          marginLeft: "0",
        },

        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiOutlinedInput-input": {
          padding: 0,
          height: "100%",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
        "& .MuiOutlinedInput-root": {
          fontWeight: fontWeight.md,
          padding: "0",
          lineHeight: lineHeight.sm,

          "&.Mui-disabled .MuiIconButton-root": {
            pointerEvents: "none",
            svg: {
              color: colors.grey[500],
            },
          },
        },
      }),
    },
  },
  MuiPickersLayout: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        "& .MuiPickersCalendarHeader-root": {
          margin: 0,
          padding: 0,
        },
        "& .MuiListItem-root": {
          padding: "0px",
          margin: "0px",
          position: "relative",
          width: "auto",
          borderRadius: radius.md,
          "& .MuiButtonBase-root": {
            paddingX: 0,
            margin: 0,
            border: `1px solid ${colors.grey[500]}`,
            fontSize: typography.fontSize.sm,
            height: 40,
            borderRadius: radius.md,
            "& .MuiChip-label": {
              paddingLeft: "15px",
              paddingRight: "15px",
            },
          },
        },
        "& .MuiPickersLayout-shortcuts": {
          order: 2,
          width: "100%",
          marginBottom: theme.spacing(4),
          overflowX: "auto",
          display: "flex",
          flexWrap: "wrap",
          minWidth: "fit-content",
          padding: 0,
          gap: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            flexWrap: "nowrap",
          },
          marginTop: 20,
        },
        "& .MuiPickersLayout-actionBar": {
          order: 3,
          alignSelf: "flex-end",
          width: "auto",
          [theme.breakpoints.up("sm")]: {
            order: 2,
            marginTop: -60,
            padding: 0,
          },
          ".MuiButton-root": {
            color: theme.palette.info.contrastText,
            backgroundColor: "transparent",
            "&:last-child": {
              backgroundColor: theme.palette.info.main,
              color: theme.palette.info.contrastText,
            },
          },
        },
      }),
    },
  },
  MuiDateRangePickerToolbar: {
    styleOverrides: {
      root: {
        "& .MuiTypography-h5": {
          alignContent: "center",
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      root: {
        padding: "0",
        "& .MuiTypography-overline": {
          fontSize: typography.fontSize.xs,
          fontWeight: fontWeight.sm,
          lineHeight: lineHeight.md,
          color: colors.grey[600],
          marginBottom: 5,
        },
      },
    },
  },
  MuiPickersToolbarButton: {
    styleOverrides: {
      root: {
        minHeight: "auto",
        alignItems: "center",
        ":hover": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  MuiPickersToolbarText: {
    styleOverrides: {
      root: {
        fontSize: typography.fontSize.xl3,
        fontWeight: fontWeight.lg,
      },
    },
  },
  MuiPickersPopper: {
    styleOverrides: {
      root: ({ theme }) => ({
        "&.dateRangePickerPopper .MuiPaper-root": {
          top: "10px",
          backgroundImage: "none",
          backgroundColor: theme.palette.background.paper,
          borderRadius: radius.lg,
          boxShadow: theme.shadows[10],
          border: `1px solid ${theme.palette.text.secondary}`,
        },
      }),
    },
  },
};

export default pickerCustomizations;
