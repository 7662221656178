import type { CustomSeriesOption } from "echarts";
import type { EchartsEventParams } from "../../shared/types/EchartsEventParams";
import type { OperationTypes } from "./makeSeries";
import { removeStringSpace } from "library-frontend-utils/helpers";
import type { Colors, DataPoint } from "../types";
import type ReactEChartsCore from "echarts-for-react/lib/core";
import type { Theme } from "@mui/joy";

export const chartEventHandlers = ({
  onClick,
  echartsComponentRef,
  disabledSeriesRef,
  theme,
  colors,
}: {
  onClick: (e: Event, data: [Date, Date, string]) => void;
  echartsComponentRef: React.MutableRefObject<ReactEChartsCore | null>;
  disabledSeriesRef: React.MutableRefObject<Record<string, boolean>>;
  theme: Theme;
  colors: Colors;
}) => ({
  click: (
    e: EchartsEventParams<
      [
        number,
        Date,
        Date,
        OperationTypes,
        string,
        string,
        string,
        boolean,
        boolean,
        string,
        string,
        string,
      ]
    >
  ) => {
    onClick(new Event("click"), [e.data[1], e.data[2], e.data[11]]);
  },
  mouseover: (
    e: EchartsEventParams<
      [number, Date, Date, OperationTypes, string, string, string, Event]
    > & {
      event: { target: { name: string } };
      info?: { isDisabled: boolean };
    }
  ) => {
    const { name: targetName } = e.event.target;
    const echartsInstance = echartsComponentRef.current?.getEchartsInstance();

    const showTooltip = (shouldShow: boolean) => {
      echartsInstance?.setOption({
        tooltip: {
          show: shouldShow,
        },
      });
    };

    if (
      targetName === "external" ||
      (targetName === "internal" && e.info?.isDisabled)
    ) {
      showTooltip(false);
      return;
    }

    showTooltip(true);
  },
  legendSelectChanged: (e: {
    name: string;
    selected: Record<string, boolean>;
    type: string;
  }) => {
    const { name, selected } = e;
    const echartsInstance = echartsComponentRef.current?.getEchartsInstance();
    if (!echartsInstance) return;

    const options = echartsInstance.getOption();

    const updateCustomStyle = (series: CustomSeriesOption) => {
      if (!series.name) return series;
      const name =
        typeof series.name === "string" ? removeStringSpace(series.name) : "";

      const isDisabled = disabledSeriesRef.current[series.name];
      const disableColor =
        theme.palette.mode === "dark"
          ? theme.palette.neutral[600]
          : theme.palette.neutral[300];

      return {
        ...series,
        itemStyle: {
          color: isDisabled
            ? disableColor
            : colors.operations[name as OperationTypes],
        },
        data: (series.data as DataPoint[]).map((item: DataPoint) => {
          const seriesName = series.name as OperationTypes;
          return [
            ...item.slice(0, -2),
            disabledSeriesRef.current[seriesName],
            item[item.length - 1],
          ];
        }),
      };
    };

    disabledSeriesRef.current[name] = !disabledSeriesRef.current[name];

    let updatedSeries: typeof options.series = [];
    if (Array.isArray(options.series)) {
      updatedSeries = options.series.map(updateCustomStyle);
    } else if (options.series) {
      updatedSeries = updateCustomStyle(options.series as CustomSeriesOption);
    }

    echartsInstance.setOption({
      ...options,
      legend: {
        selected: {
          ...selected,
          [name]: true,
        },
      },
      series: updatedSeries,
    });
  },
});
