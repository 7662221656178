import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Link, Typography } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";

type BaseSectionErrorStateProps = {
  children: React.ReactNode;
  isError?: boolean;
  isEmpty?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  title?: string;
  showGradient?: boolean;
  variant?: "soft" | "solid";
};

type SectionErrorStatePropsWithDescription = BaseSectionErrorStateProps & {
  description: React.ReactNode;
};

type SectionErrorStatePropsWithLinks = BaseSectionErrorStateProps & {
  onOpenFeedbackClick: () => void;
  onAskForHelpClick: () => void;
};

const translationStrings = [
  "Error fetching data",
  "Please refresh the page or try again later.",
  "No data to show!",
  "There are no results for the requested query.",
  "The source to which we're trying to connect isn't responding. Please try refreshing the page.",
  "If the issue persists, ",
  "send a feedback",
  "or",
  "ask for help.",
] as const;

const getMessageType = (isError?: boolean, isEmpty?: boolean) => {
  if (isError) return "error";
  if (isEmpty) return "empty";
  return undefined;
};

export default function SectionErrorState({
  children,
  isError = false,
  isEmpty = false,
  translations,
  title,
  showGradient = true,
  variant = "solid",
  ...rest
}: SectionErrorStatePropsWithDescription | SectionErrorStatePropsWithLinks) {
  const t = { ...defaultTranslations(translationStrings), ...translations };
  const messageType = getMessageType(isError, isEmpty);

  if (!messageType) return children;

  const renderDescription = () => {
    if ("description" in rest) return rest.description;
    if (messageType === "empty")
      return t["There are no results for the requested query."];
    return (
      <>
        <Typography level="title-sm" textColor="text.secondary" pb={2}>
          {
            t[
              "The source to which we're trying to connect isn't responding. Please try refreshing the page."
            ]
          }
        </Typography>
        <Typography level="title-sm" textColor="text.secondary">
          {t["If the issue persists, "]}
          <Link
            color="neutral"
            textColor="text.secondary"
            underline="always"
            onClick={rest.onOpenFeedbackClick}
            text-decoration-color="none"
          >
            {t["send a feedback"]}
          </Link>{" "}
          {t["or"]}{" "}
          <Link
            color="neutral"
            textColor="text.secondary"
            underline="always"
            onClick={rest.onAskForHelpClick}
            text-decoration-color="text.secondary"
          >
            {t["ask for help."]}
          </Link>
        </Typography>
      </>
    );
  };

  const getCustomTitle = () => {
    if (title) return title;
    return messageType === "empty"
      ? t["No data to show!"]
      : t["Error fetching data"];
  };

  const getBorderColor = () => {
    if (showGradient) return "none";

    if (isError) return "1px solid var(--joy-palette-warning-outlinedBorder)";
    if (isEmpty) return "1px dashed var(--joy-palette-background-level2)";

    return "none";
  };

  const getGradient = () => {
    if (!showGradient) {
      return "transparent";
    }
    if (variant === "soft") {
      return "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--joy-palette-background-surface) 100%)";
    }
    return "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, var(--joy-palette-background-body) 100%)";
  };

  return (
    <Grid
      container
      height="inherit"
      position="relative"
      alignItems="center"
      justifyContent="center"
      borderRadius="sm"
      overflow="hidden"
      flexWrap="nowrap"
      border={getBorderColor()}
    >
      <Grid
        container
        position="absolute"
        bottom={0}
        left={0}
        width="100%"
        height="100%"
        zIndex={1}
        textAlign="center"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        sx={{
          background: getGradient(),
        }}
      >
        <Grid xs={12}>
          <Typography
            level="h3"
            color={messageType === "empty" ? "neutral" : "warning"}
          >
            <FontAwesomeIcon
              icon={messageType === "empty" ? faEyeSlash : faCircleExclamation}
            />
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography level="h4" mb={2}>
            {getCustomTitle()}
          </Typography>
        </Grid>
        <Grid xs={12}>{renderDescription()}</Grid>
      </Grid>
      <Box zIndex={0} position="relative" width="100%">
        {children}
      </Box>
    </Grid>
  );
}
