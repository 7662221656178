import type { Components, Theme } from "@mui/joy";

const scrollbarStyles = {
  overflowX: "auto" as const,
  "&::-webkit-scrollbar": {
    height: 8,
    maxHeight: 8,
  },
};

const modalCustomizations: Components<Theme> = {
  JoyModalClose: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.radius.xl,
      }),
    },
  },
  JoyDialogTitle: {
    defaultProps: {
      level: "h3",
      sx: { fontWeight: "sm" },
    },
  },
  JoyDialogContent: {
    styleOverrides: {
      root: () => ({
        ...scrollbarStyles,
        "& *": scrollbarStyles,
      }),
    },
  },
};

export default modalCustomizations;
