import type { CssVarsThemeOptions } from "@mui/joy/styles";
import { extendTheme } from "@mui/joy/styles";
import { deepmerge } from "@mui/utils";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import colors from "../tokens/colors";
import breakpoints from "../tokens/breakpoints";
import {
  autocompleteCustomizations,
  badgeCustomizations,
  buttonCustomizations,
  checkboxCustomizations,
  chipCustomizations,
  inputCustomizations,
  listCustomizations,
  modalCustomizations,
  radioCustomizations,
  selectCustomizations,
  sliderCustomizations,
  switchCustomizations,
  tableCustomizations,
  tabsCustomizations,
  tooltipCustomizations,
} from "./customizations";
import radius from "../tokens/radius";
import shadow from "../tokens/shadow";

declare module "@mui/joy/styles" {
  interface TypographySystemOverrides {
    "title-xs": true;
    kpi: true;
  }
}

const createTheme = (override?: CssVarsThemeOptions) => {
  const defaultThemeOptions: CssVarsThemeOptions = {
    shadow: shadow,
    colorSchemes: {
      light: {
        palette: {
          //taken from https://www.figma.com/file/HfT3a7QPgAqrOl3NjqJGTn/Prototype?node-id=1%3A515&mode=dev
          neutral: {
            ...colors.grey,
            softColor: "var(--joy-palette-neutral-700)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            softBg: "var(--joy-palette-neutral-100)",
            softHoverBg: "var(--joy-palette-neutral-200)",
            softActiveBg: "var(--joy-palette-neutral-300)",
            softDisabledBg: "var(--joy-palette-neutral-100)",
            solidActiveBg: "var(--joy-palette-neutral-900)",
            solidBg: "var(--joy-palette-neutral-700)",
            solidColor: "var(--joy-palette-common-white)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            solidDisabledBg: "var(--joy-palette-neutral-100)",
            solidHoverBg: "var(--joy-palette-neutral-800)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400)",
            plainColor: "var(--joy-palette-neutral-800)",
            plainDisabledColor: "var(--joy-palette-neutral-400)",
            plainHoverBg: "var(--joy-palette-neutral-200)",
            plainActiveBg: "var(--joy-palette-neutral-300)",
          },
          primary: {
            ...colors.blue,
            softColor: "var(--joy-palette-primary-700)",
            softDisabledBg: "var(--joy-palette-neutral-100)",
            softBg: "var(--joy-palette-primary-50)",
            softHoverBg: "var(--joy-palette-primary-100)",
            softActiveBg: "var(--joy-palette-primary-200)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidBg: "var(--joy-palette-primary-600)",
            solidHoverBg: "var(--joy-palette-primary-700)",
            solidActiveBg: "var(--joy-palette-primary-800)",
            solidDisabledBg: "var(--joy-palette-neutral-100)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            outlinedColor: "var(--joy-palette-primary-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400)",
            plainColor: "var(--joy-palette-primary-700)",
            plainDisabledColor: "var(--joy-palette-neutral-400)",
          },
          danger: {
            ...colors.red,
            softBg: "var(--joy-palette-danger-50)",
            softHoverBg: "var(--joy-palette-danger-100)",
            softActiveBg: "var(--joy-palette-danger-200)",
            softDisabledBg: "var(--joy-palette-neutral-100)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidBg: "var(--joy-palette-danger-600)",
            solidHoverBg: "var(--joy-palette-danger-700)",
            solidActiveBg: "var(--joy-palette-danger-800)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            outlinedColor: "var(--joy-palette-danger-700)",
            outlinedHoverBg: "var(--joy-palette-danger-100)",
            plainColor: "var(--joy-palette-danger-700)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
          },
          success: {
            ...colors.green,
            softBg: "var(--joy-palette-success-50)",
            softHoverBg: "var(--joy-palette-success-100)",
            softActiveBg: "var(--joy-palette-success-200)",
            softDisabledBg: "var(--joy-palette-neutral-100)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            solidBg: "var(--joy-palette-success-600)",
            solidHoverBg: "var(--joy-palette-success-700)",
            solidActiveBg: "var(--joy-palette-success-800)",
            solidDisabledBg: "var(--joy-palette-neutral-200)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            outlinedColor: "var(--joy-palette-success-700)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400)",
            plainColor: "var(--joy-palette-success-700)",
            plainDisabledColor: "var(--joy-palette-neutral-400)",
          },
          warning: {
            ...colors.yellow,
            softActiveBg: "var(--joy-palette-warning-200)",
            softActiveColor: "var(--joy-palette-warning-800)",
            softBg: "var(--joy-palette-warning-50)",
            softColor: "var(--joy-palette-warning-700)",
            softDisabledBg: "var(--joy-palette-neutral-100)",
            softDisabledColor: "var(--joy-palette-neutral-500)",
            softHoverBg: "var(--joy-palette-warning-100)",
            solidActiveBg: "var(--joy-palette-warning-800)",
            solidBg: "var(--joy-palette-warning-500)",
            solidColor: "var(--joy-palette-common-white)",
            solidDisabledBg: "var(--joy-palette-neutral-100)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            solidHoverBg: "var(--joy-palette-warning-700)",
            outlinedActiveBg: "var(--joy-palette-warning-200)",
            outlinedBorder: "var(--joy-palette-warning-300)",
            outlinedColor: "var(--joy-palette-warning-700)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-200)",
            outlinedDisabledColor: "var(--joy-palette-neutral-400)",
            outlinedHoverBg: "var(--joy-palette-warning-100)",
            plainColor: "var(--joy-palette-warning-700)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            plainActiveBg: "var(--joy-palette-warning-200)",
            plainHoverBg: "var(--joy-palette-warning-50)",
          },
          background: {
            backdrop: colors.alphaWhite[200],
            body: "var(--joy-palette-neutral-50)",
            surface: "var(--joy-palette-common-white)",
            level1: "var(--joy-palette-neutral-200)",
            level2: "var(--joy-palette-common-white)",
            level3: "var(--joy-palette-neutral-50)",
            tooltip: "var(--joy-palette-neutral-900)",
          },
          text: {
            icon: "var(--joy-palette-neutral-900)",
            primary: "var(--joy-palette-neutral-900)",
            secondary: "var(--joy-palette-neutral-700)",
            tertiary: "var(--joy-palette-neutral-500)",
          },
        },
      },
      dark: {
        palette: {
          neutral: {
            ...colors.grey,
            softColor: "var(--joy-palette-neutral-50)",
            softActiveColor: "var(--joy-palette-common-white)",
            softDisabledColor: "var(--joy-palette-neutral-400)",
            softDisabledBg: "var(--joy-palette-neutral-800)",
            solidActiveBg: "var(--joy-palette-neutral-300)",
            solidBg: "var(--joy-palette-neutral-50)",
            solidColor: "var(--joy-palette-common-black)",
            solidDisabledColor: "var(--joy-palette-neutral-400)",
            solidDisabledBg: "var(--joy-palette-neutral-800)",
            solidHoverBg: "var(--joy-palette-neutral-200)",
            outlinedBorder: "var(--joy-palette-neutral-600)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            plainColor: "var(--joy-palette-neutral-50)",
            plainHoverBg: "var(--joy-palette-neutral-700)",
            plainActiveBg: "var(--joy-palette-neutral-600)",
          },
          primary: {
            ...colors.blue,
            softColor: "var(--joy-palette-primary-100)",
            softActiveColor: "var(--joy-palette-primary-50)",
            softDisabledColor: "var(--joy-palette-neutral-400)",
            softBg: "var(--joy-palette-primary-900)",
            softHoverBg: "var(--joy-palette-primary-800)",
            softActiveBg: "var(--joy-palette-primary-700)",
            solidDisabledColor: "var(--joy-palette-neutral-500)",
            solidBg: "var(--joy-palette-primary-700)",
            solidHoverBg: "var(--joy-palette-primary-600)",
            solidActiveBg: "var(--joy-palette-primary-500)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            outlinedBorder: "var(--joy-palette-primary-600)",
            plainColor: "var(--joy-palette-primary-200)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
          },
          danger: {
            ...colors.red,
            softBg: "var(--joy-palette-danger-900)",
            softHoverBg: "var(--joy-palette-danger-800)",
            softActiveBg: "var(--joy-palette-danger-700)",
            softColor: "var(--joy-palette-danger-100)",
            softActiveColor: "var(--joy-palette-danger-50)",
            softDisabledColor: "var(--joy-palette-neutral-400)",
            softDisabledBg: "var(--joy-palette-neutral-800)",
            solidBg: "var(--joy-palette-danger-700)",
            solidHoverBg: "var(--joy-palette-danger-600)",
            solidActiveBg: "var(--joy-palette-danger-500)",
            solidDisabledBg: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-400)",
            outlinedBorder: "var(--joy-palette-danger-600)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800)",
            outlinedHoverBg: "var(--joy-palette-danger-800)",
            plainColor: "var(--joy-palette-danger-200)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
          },
          success: {
            ...colors.green,
            softBg: "var(--joy-palette-success-900)",
            softHoverBg: "var(--joy-palette-success-800)",
            softActiveBg: "var(--joy-palette-success-700)",
            softColor: "var(--joy-palette-success-100)",
            softActiveColor: "var(--joy-palette-success-50)",
            softDisabledColor: "var(--joy-palette-neutral-400)",
            softDisabledBg: "var(--joy-palette-neutral-800)",
            solidBg: "var(--joy-palette-success-700)",
            solidHoverBg: "var(--joy-palette-success-600)",
            solidActiveBg: "var(--joy-palette-success-500)",
            solidDisabledColor: "var(--joy-palette-neutral-400)",
            solidDisabledBg: "var(--joy-palette-neutral-800)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            outlinedBorder: "var(--joy-palette-neutral-600)",
            plainColor: "var(--joy-palette-success-200)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
          },
          warning: {
            ...colors.yellow,
            softActiveBg: "var(--joy-palette-warning-700)",
            softActiveColor: "var(--joy-palette-warning-50)",
            softBg: "var(--joy-palette-warning-900)",
            softColor: "var(--joy-palette-warning-100)",
            softDisabledBg: "var(--joy-palette-neutral-800)",
            softDisabledColor: "var(--joy-palette-neutral-400)",
            softHoverBg: "var(--joy-palette-warning-800)",
            solidActiveBg: "var(--joy-palette-warning-500)",
            solidBg: "var(--joy-palette-warning-700)",
            solidColor: "var(--joy-palette-common-white)",
            solidDisabledBg: "var(--joy-palette-neutral-800)",
            solidDisabledColor: "var(--joy-palette-neutral-400)",
            solidHoverBg: "var(--joy-palette-warning-600)",
            outlinedActiveBg: "var(--joy-palette-warning-700)",
            outlinedBorder: "var(--joy-palette-warning-600)",
            outlinedColor: "var(--joy-palette-warning-100)",
            outlinedDisabledBorder: "var(--joy-palette-neutral-800)",
            outlinedDisabledColor: "var(--joy-palette-neutral-500)",
            outlinedHoverBg: "var(--joy-palette-warning-800)",
            plainColor: "var(--joy-palette-warning-100)",
            plainDisabledColor: "var(--joy-palette-neutral-500)",
            plainActiveBg: "var(--joy-palette-warning-700)",
            plainHoverBg: "var(--joy-palette-warning-800)",
          },
          background: {
            backdrop: colors.alphaBlack[800],
            body: "var(--joy-palette-common-black)",
            surface: "var(--joy-palette-neutral-900)",
            level1: "var(--joy-palette-neutral-800)",
            level2: "var(--joy-palette-neutral-700)",
            level3: "var(--joy-palette-neutral-600)",
            popup: "var(--joy-palette-neutral-900)",
            tooltip: "var(--joy-palette-neutral-50)",
          },
          text: {
            icon: "var(--joy-palette-neutral-100)",
            primary: "var(--joy-palette-neutral-50)",
          },
        },
      },
    },
    radius: radius,
    spacing: 4,
    fontWeight: { xs: 200, md: 400 },
    fontSize: {
      xl4: "2.5rem",
      xl3: "2rem",
      lg: "1rem",
      md: "0.875rem",
      sm: "0.75rem",
      xs: "0.625rem",
    },
    lineHeight: {
      xs: 1.2,
      sm: 1.3,
      md: 1.7,
      lg: 1.5,
      xl: 1.4,
    },
    typography: {
      h1: {
        letterSpacing: 0,
      },
      h2: {
        letterSpacing: 0,
      },
      h3: {
        letterSpacing: 0,
      },
      h4: {
        letterSpacing: 0,
        lineHeight: 1.2,
      },
      "title-md": {
        lineHeight: 1.2,
      },
      "title-xs": {
        fontSize: "0.625rem",
        lineHeight: 1.2,
        fontWeight: "var(--joy-fontWeight-sm)",
      },
      kpi: {
        fontSize: "5.125rem",
        fontWeight: "var(--joy-fontWeight-xs)",
        lineHeight: 1,
        letterSpacing: -0.5,
      },
    },
    breakpoints: {
      values: breakpoints,
    },
    components: {
      ...inputCustomizations,
      ...selectCustomizations,
      ...checkboxCustomizations,
      ...autocompleteCustomizations,
      ...radioCustomizations,
      ...buttonCustomizations,
      ...tableCustomizations,
      ...modalCustomizations,
      ...sliderCustomizations,
      ...switchCustomizations,
      ...chipCustomizations,
      ...badgeCustomizations,
      ...tabsCustomizations,
      ...tooltipCustomizations,
      ...listCustomizations,
    },
  };

  return extendTheme(deepmerge(defaultThemeOptions, override));
};

export default createTheme;
