import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "nested-row-divider";
const width = 1;
const height = 60;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData = "M1 0V30ZM1 60V30ZM1 30H13Z M1 0V30M1 30V60M1 30H13";
const prefix = "fac";

export const NestedRowDivider: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(NestedRowDivider);
