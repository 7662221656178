import type { Theme } from "@mui/material";
import colors from "../../tokens/colors";
import type { Components } from "@mui/material/styles";
import radius from "../../tokens/radius";
import { fontSize } from "../../tokens/typography";

const tabsCustomizations: Components<Theme> = {
  MuiTab: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
    },
  },
  MuiTabs: {
    defaultProps: {
      TabIndicatorProps: {
        sx: { display: "none", height: 2 },
      },
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        const isInfo = ownerState.color === "info";
        const isUnderline = ownerState.itemType === "underline";

        const bgColor = {
          primary: theme.palette.primary.main,
          info: theme.palette.common.white,
          error: theme.palette.error.dark,
          success: theme.palette.success.dark,
          warning: theme.palette.warning.dark,
          secondary: theme.palette.secondary.dark,
          default: theme.palette.common.white,
        }[ownerState.color ?? "default"];

        const commonTabStyles = {
          gap: theme.spacing(1),
          textTransform: "none",
          transition: "none",
          padding: theme.spacing(2, 3),
          fontSize: fontSize.md,
          lineHeight: 1,
          color: theme.palette.text.primary,
          "&.Mui-disabled": {
            color: colors.grey[500],
          },
        };

        const baseStyles = {
          backgroundColor: theme.palette.info.main,
          "& .MuiTab-root": commonTabStyles,
        };

        if (isUnderline) {
          return {
            ...baseStyles,
            backgroundColor: "transparent",
            "& .MuiTabs-indicator": {
              backgroundColor: isInfo ? theme.palette.text.primary : bgColor,
              display: "block",
            },
            "& .MuiTab-root": {
              ...commonTabStyles,
              minHeight: 48,
              borderBottom: `2px solid transparent`,
              "&.Mui-selected": {
                borderBottom: `2px solid ${isInfo ? theme.palette.text.primary : theme.palette.info.main}`,
                color: isInfo ? theme.palette.text.primary : bgColor,
              },
            },
          };
        }

        return {
          ...baseStyles,
          borderRadius: radius.xl,
          paddingBlock: theme.spacing(1),
          minHeight: 40,
          "& .MuiTab-root": {
            ...commonTabStyles,
            minHeight: 40,
            marginInline: theme.spacing(1),
            borderRadius: radius.xl,
            backgroundColor: "transparent",
            alignItems: "center",
            "&.Mui-selected": {
              backgroundColor: bgColor,
              color: isInfo ? colors.grey[800] : theme.palette.common.white,
            },
          },
        };
      },
    },
  },
};

export default tabsCustomizations;
