import { Box, Typography, IconButton, Divider, Grid } from "@mui/joy";
import type { TopLevelFormatterParams } from "echarts/types/dist/shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowRight } from "library-design-system/icons/ArrowRight";
import type { ComponentProps } from "react";
import colors from "library-design-system/tokens/colors";

type TooltipProps = {
  param?: TopLevelFormatterParams;
  title: string;
  onClick?: ComponentProps<typeof IconButton>["onClick"];
  body?: {
    label: string;
    value: string;
    startDecorator?: ComponentProps<typeof Typography>["startDecorator"];
  }[];
  onMouseLeave?: () => void;
};

export default function Tooltip({
  title,
  onClick,
  body = [],
  onMouseLeave,
}: TooltipProps) {
  return (
    <Box
      sx={{
        minWidth: onClick || body.length > 0 ? 200 : 0,
        borderRadius: "sm",
        background: "var(--joy-palette-background-surface)",
        border: `1px solid ${colors.grey[300]}}`,
      }}
      onMouseLeave={onMouseLeave}
    >
      <Grid container justifyContent="space-between" p={2}>
        <Typography
          level="title-md"
          textColor={colors.grey[800]}
          sx={{ fontWeight: "lg" }}
        >
          {title}
        </Typography>
        {onClick && (
          <IconButton
            size="sm"
            variant="plain"
            sx={{
              height: "auto",
              minHeight: 0,
              minWidth: 0,
              padding: 0,
              "& svg": {
                width: "16.8px",
                height: "16.8px",
                color: colors.grey[800],
              },
              "&:hover": { background: colors.grey[200] },
              "&:active": { background: colors.grey[300] },
            }}
            onClick={onClick}
          >
            <FontAwesomeIcon icon={ArrowRight} size="sm" />
          </IconButton>
        )}
      </Grid>
      {body.length !== 0 && (
        <Divider sx={{ bgcolor: "var(--joy-palette-neutral-300)" }} />
      )}
      {body.map(({ label, value, startDecorator }, key) => (
        <Grid
          container
          key={key}
          justifyContent="space-between"
          pt={key === 0 ? 2 : 1}
          pb={key === body.length - 1 ? 2 : 0}
          px={2}
        >
          <Typography
            level="title-sm"
            textColor={colors.grey[800]}
            sx={{ fontWeight: "sm" }}
            startDecorator={startDecorator}
          >
            {label}
          </Typography>
          <Typography
            level="title-sm"
            textColor={colors.grey[800]}
            sx={{ fontWeight: "xl" }}
          >
            {value}
          </Typography>
        </Grid>
      ))}
    </Box>
  );
}
